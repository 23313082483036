import React, { useEffect } from "react";
import { Button, Form, Carousel, Row, Col, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import { RequestNewVersion } from "../../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import RenderPdfDocument from "../../helper_components/RenderDocumentPdf";
import { selectStylesDefault, renderErrorsByInputName, selectStylesError } from "../my_expedients/expedients_list_logic";
import TableUploadManyFiles from "../../helper_components/TableUploadManyFiles";
import reactStringReplace from "react-string-replace";
import useLegalRepresentativeText from "../my_expedients/hooks/useLegalRepresentativeText";
import TextTooltip from "../../tootip/TextTooltip";
import { getDisplayNameOfType } from "./services/getDisplayNameOfType";
import { downloadFromABlob } from "../../../services/download/downloadFromABlob";
import DownloadPreviewDocumentModal from "./modals/DownloadPreviewDocumentModal";
import PreviewPdfModalProvider from "./context/previewPdfModalContext";
import { getLocaleDateString } from "../../../services/dataFormatting/getLocaleDateString";
import _, {isArray, range} from "lodash";
import { judgmentSubtypes } from "./constants/judgmentSubtypes";
import { getLabelByJudgmentSubtype } from "./services/getLabelByJudgmentSubtype";
import { getDocumentSubtype } from "./services/getDocumentSubtype";
import ReplicasManagementModal from "./modals/replicasManagementModal/ReplicasManagementModal";
import { procedureTypeOptions } from "./constants/procedureTypeOptions";
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import useCreateTocaState from "./customHooks/useCreateTocaState";
import PartsRelatedToTheExpedientTable from "./components/partsRelatedToTheExpedientTable/PartsRelatedToTheExpedientTable";
import { getLabelFromDictionary } from "../../../config/dictionary";
import axios from "axios";
import { re } from "../../../services/sat/asn1/base64";
import { handleRequest } from "../../../services/requests/handleRequest";
import { getPartiesByExpedientId } from "./modals/partiesModal/helpers/logicDataParties";

export const columns = [
  {
    dataField: "actions",
    text: "Documentos",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
    classes: 'fixed-cell'
  },
  {
    dataField: "time_indicator",
    text: "Indicador",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "filename",
    text: "Nombre",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
    escape: false
  },
  {
    dataField: "alias",
    text: "Etiqueta",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "document_type_translated",
    text: "Tipo",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "created_date",
    text: "Creación",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "comment",
    text: "Comentarios",
    sort: true,
    style: { textAlign: "justify" },
    headerStyle: { textAlign: "center" }
  }
];

export const searchDocumentsByExpedientNumberAndFilename = async (
  expedientId,
  setDataExpedient,
  setDataDocuments,
  setTextNotDataDocuments,
  setTotalDocuments,
  sizePerPage,
  page,
  loader,
  setStateDocuments,
  setCommentModal,
  setShowCommentModal,
  search,
  onManageViewingPermissionsButtonClick,
  setExpedientParts,
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/documents/${expedientId}/${sizePerPage}?page=${page}&search=${search}`,
    "get",
    null,
    {}
  );

  const responseDocumentsExpedient = await request.executeRequest();
  if (responseDocumentsExpedient.code === 200) {

    if (responseDocumentsExpedient.response.expedient && responseDocumentsExpedient.response.expedient) {

      const documents = await formattedDocuments(
        responseDocumentsExpedient.response,
        loader,
        setStateDocuments,
        setCommentModal,
        setShowCommentModal,
        onManageViewingPermissionsButtonClick
      );
      const expedient = {
        ...responseDocumentsExpedient.response.expedient,
        date: responseDocumentsExpedient.response.date ? new Date(responseDocumentsExpedient.response.date).toLocaleDateString('es-MX', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        }) : null
      };
      const documentsHighlighted = hightlightSearch(documents, search);
      setDataExpedient(expedient);
      setDataDocuments(documentsHighlighted);
      setTotalDocuments(responseDocumentsExpedient.response.total);
      setTextNotDataDocuments(
        responseDocumentsExpedient.response.documents.length > 0 ?
          "" :
          responseDocumentsExpedient.response.message
      );
    }
  }
};

const hightlightSearch = (documents, search) => {
  return documents.map(d => ({
    ...d,
    filename: reactStringReplace(d.filename, search, (match, i) => (<mark>{match}</mark>))
  }));
}

const formatedParties = (parties) => {
  return parties.reduce((acc, party) => {
    if (party.party_data.maternal_surname) {
    }
    let formatedData = {};
    if (party.party_type) {
      formatedData.party_type = party.party_type;
    }
    if (party.party_data) {
      let names = '';
      names += ` ${party.party_data.name}` || '';
      names += ` ${party.party_data.names}` || '';
      names += ` ${party.party_data.firstname}` || '';
      names += ` ${party.party_data.paternal_surname}` || '';
      names += ` ${party.party_data.secondname}` || '';
      names += ` ${party.party_data.maternal_surname}` || '';

      formatedData = {
        ...formatedData,
        email: party.party_data.email,
        names: names,
        business_name: party.party_data.business_name || party.party_data.alias || null
      };
    }

    if (Object.keys(formatedData).length > 0) {
      acc.push(formatedData);
    }

    return acc;
  }, []);

}

export const dowloadExpedient = async (electronicExpedientId) => {
  const { authentication_token_03: userToken } = Cookies.get();
  const cover = document.getElementById('coverPDF');
  const coberContent = cover.outerHTML;
  
  try {
    const dowloadExpedientResponse = await fetch(
      `${process.env.REACT_APP_URL_API_LARAVEL}/download_zip/${electronicExpedientId}`,
      {
        method: "POST",
        headers: {  
          "Content-Type": "application/json",
          "Authorization": userToken
        },
        body: JSON.stringify({ cover: coberContent })
      }
    );

    if (Number(dowloadExpedientResponse.status) !== 200) {
      NotificationManager.error(`Error de servidor: ${dowloadExpedientResponse.status}`);
      return
    }

    const isExpedientResponseAZipFile = dowloadExpedientResponse.headers.get('Content-Type').startsWith('application/zip');

    if (!isExpedientResponseAZipFile) {
      const expedientResponseJson = await dowloadExpedientResponse.json();
      NotificationManager.error(expedientResponseJson.data.message);
      return
    }
    
    const expedientBlob = await dowloadExpedientResponse.blob();
    downloadFromABlob(expedientBlob, `${getLabelFromDictionary('global', 'singleLowerMainName')}_${electronicExpedientId}`, 'zip');

  } catch (error) {
    NotificationManager.error('Ocurrió un error al intentar descargar el archivo');
  }
}

export const getDocumentsByExpedientNumber = async (
  expedientId,
  setDataExpedient,
  setDataDocuments,
  setTextNotDataDocuments,
  setTotalDocuments,
  sizePerPage,
  page,
  loader,
  setStateDocuments,
  setCommentModal,
  setShowCommentModal,
  onManageViewingPermissionsButtonClick,
  setExpedientParts,
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/documents/${expedientId}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseDocumentsExpedient = await request.executeRequest();

  if (responseDocumentsExpedient && responseDocumentsExpedient.code === 200) {

    if (responseDocumentsExpedient.response && responseDocumentsExpedient.response.expedient) {

      const documents = await formattedDocuments(
        responseDocumentsExpedient.response,
        loader,
        setStateDocuments,
        setCommentModal,
        setShowCommentModal,
        onManageViewingPermissionsButtonClick
      );
      const expedient = {
        ...responseDocumentsExpedient.response.expedient,
        date: responseDocumentsExpedient.response.date ? new Date(responseDocumentsExpedient.response.date).toLocaleDateString('es-MX', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        }) : null
      };
      setDataExpedient(expedient);
      setDataDocuments(documents);
      setTotalDocuments(responseDocumentsExpedient.response.total);
      setTextNotDataDocuments(
        responseDocumentsExpedient.response.documents.length > 0 ?
          "" :
          responseDocumentsExpedient.response.message
      );
    }
  }
};

const contentListToOmit = [
  null,
  'null',
  'null.',
  'null. ',
  undefined,
  'undefined',
  'undefined.',
  'undefined. ',
]

const formattedDocuments = (dataResponse, loader, setStateDocuments, setCommentModal, setShowCommentModal, onManageViewingPermissionsButtonClick) => {
  let { expedient, documents } = dataResponse;
  const userMatch = String(expedient['user_id']) === Cookies.get('userId_03');
  let doc_type = 'No definido';

  return documents.map((document) => {
    document.full_comment = document.comment;

    const commentIsAnInvalidValue = contentListToOmit.includes(document.comment);

    if (commentIsAnInvalidValue) {
      document.comment = '';
    } else {
      document.comment = (
        <div>
          {document.comment.length <= 110
            ? document.comment
            : (
              <div>
                {
                  document.comment.slice(0, 110)}... <strong style={{ cursor: 'pointer' }} onClick={() => {
                    setCommentModal(document.full_comment);
                    setShowCommentModal(true);
                  }}>Ver más</strong>
              </div>
            )
          }
        </div>
      );
    }

    const documentSubtype = getDocumentSubtype(document);
    doc_type = getDisplayNameOfType(document.document_type, documentSubtype);

    const timeIndicator = checkExpiredTime(document.expired);
    document['filename'] = replaceCommaLine(document['filename']);
    document["document_type_translated"] = doc_type;
    let isATurnedExpedient = dataResponse.expedient.turned;
    document["created_date"] = document.created_at ? getLocaleDateString(document.created_at, true) : '';

    const areReplicasVisible = document.areReplicasVisible;
    const doAreReplicasVisibleExist = typeof areReplicasVisible === 'boolean';
    const isInstancesManagementModalEnabled = doAreReplicasVisibleExist;

    document["actions"] = (
      <div className="procedures-actions d-flex flex-wrap justify-content-center" style={{ gap: '10px', minWidth: '150px' }}>
        <PreviewPdfModalProvider>
          <DownloadPreviewDocumentModal
            document={document}
            documentType={doc_type}
            isDownloadDocumentButtonShowing={userMatch}
          />
        </PreviewPdfModalProvider>
        <button
          onClick={userMatch ? () => setStateDocuments(document['id'], false) : null}
          className={
            document["evidence_path"] && userMatch ?
              "btn button-see-evidence-documents" :
              document["evidence_path"] && !userMatch ?
                "btn btn-secondary no-event-click" :
                "d-n"
          }
          title="Anexos"
        >
          <i className="ti-files" />
        </button>
        <button
          onClick={userMatch ? () => setStateDocuments(document['id'], true) : null}
          className={document['notification_path'] ? 'btn button-notifications' : 'd-n'}
          title="Notificaciones"
        >
          <i className="fas fa-file-invoice" />
        </button>

        {document['relation'].length > 0 &&
          <button
            onClick={userMatch ? () => setStateDocuments(document['relation'], false) : null}
            className={'btn button-relation-documents mL-10'}
            title="Relacionados"
          >
            <i className="ti-exchange-vertical" />
          </button>
        }

        {(!isATurnedExpedient) &&
          <button
            type="button"
            className="btn btn-warning text-white"
            data-toggle="tooltip"
            title="Gestionar permisos"
            onClick={() => onManageViewingPermissionsButtonClick(document)}
          >
            <i className="fas fa-eye"></i>
          </button>
        }

        {
          isInstancesManagementModalEnabled
          &&
          <ReplicasManagementModal
            document={document}
          />
        }
      </div>
    );
    document['time_indicator'] = (<a onClick={() => setStateDocuments(document['expirations'], true)}><span className={"dot-" + timeIndicator} /></a>);

    return document;
  });
};

export const getExpedientParts = async (
  expedientId,
  setExpedientParts
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/${expedientId}/parties`,
    "get",
    null,
    {}
  );

  const responseExpedientParts = await request.executeRequest();
  if (responseExpedientParts.code === 200) {
    const parts = responseExpedientParts.response;
    const keys = Object.keys(parts).filter((key) => key !== 'message');
  
    const partsFormatted = keys.map((key) => {
      if (key !== 'message') {
        const data = parts[key].party_data;
        let names = '';
        names += data.name && data.name !== 'null' && ` ${data.name}` || '';
        names += data.names && data.names !== 'null' && ` ${data.names}` || '';
        names += data.firstname && data.firstname !== 'null' && ` ${data.firstname}` || '';
        names += data.paternal_surname && data.paternal_surname !== 'null' && ` ${data.paternal_surname}` || '';
        names += data.secondname && data.secondname !== 'null' && ` ${data.secondname}` || '';
        names += data.maternal_surname && data.maternal_surname !== 'null' && ` ${data.maternal_surname}` || '';
        return {
          party_type: parts[key].party_type,
          bussiness_name: data.regime=== 'moral' ? data.business_name : null,
          names: names,
          email: data.email,
        }
      }
    });
    setExpedientParts(partsFormatted);
  } else {
    NotificationManager.error('Ocurrió un error al traer las partes relacionadas al expediente');
  }
};

const replaceCommaLine = (data) => {
  //convert string to array and remove whitespace
  let dataToArray = data.split(',').map(item => item.trim());
  //convert array to string replacing comma with new line
  return dataToArray.join("\n");
}

const checkExpiredTime = (dataExpired) => {
  if (dataExpired.expired_at && dataExpired.warning) {
    const expiredDate = new Date(dataExpired.expired_at);
    const todayDate = dataExpired.answered_at ? new Date(dataExpired.answered_at) : new Date();

    const expired = new Date(expiredDate.setHours(0, 0, 0, 0));
    const today = new Date(todayDate.setHours(0, 0, 0, 0));
    const timeTranscurred = (expired - today) / 86400000;

    if (timeTranscurred >= dataExpired.warning) return 'green';
    if (timeTranscurred < dataExpired.warning && timeTranscurred >= 0) return 'yellow';
    if (timeTranscurred < 0) return 'red';
  }
  return 'white';
};

export const getFilteredDocumentTypes = (hiddenList = {}) => {
  const filteredDocumentTypes = documentTypes.filter((documentTypeItem) => (
    !Boolean(hiddenList[documentTypeItem.value] && hiddenList[documentTypeItem.value].isHidden)
  ));
  return filteredDocumentTypes;
}

export const documentTypes = [
  {
    value: "promotion",
    label: "Promoción",
    isSingleSignable: true,
    isMultipleSignable: false,
    getRequiredFields: (file = {}) => {
      const commonFields = ["alias", "document_type", "file", "hasProofs", "promoter"];
      if (file.hasProofs === "si")
        return [...commonFields, "evidence_file"]
      return commonFields
    },
    getKeysDataToSend: () => ["alias", "document_type", "file", "promoter", "hasProofs", "expedient_number", "comment", "isPublishedInBulletin"],
  },
  {
    value: "agreement",
    label: "Acuerdo",
    isSingleSignable: true,
    isMultipleSignable: true,
    isBulletin: true,
    isReloadComponentByDefault: true,
    getRequiredFields: () => ["alias", "document_type", "file", "headings"],
    getKeysDataToSend: () => ["alias", "document_type", "file", "comment", "expedient_number", "selectedPromotions", "headings", "isPublishedInBulletin"],
  },
  {
    value: "privateJudgment",
    valueAlias: "judgment",
    label: "Sentencia - Versión privada",
    isSingleSignable: true,
    isMultipleSignable: true,
    isJudgmentType: true,
    isReloadComponentByDefault: true,
    getRequiredFields: () => ["alias", "document_type", "file"],
    getKeysDataToSend: () => ["alias", "document_type", "file", "comment", "expedient_number", "judgment_type", "isPublishedInBulletin"],
    subtypes: judgmentSubtypes,
    getLabelBySubtype: getLabelByJudgmentSubtype,
  },
  {
    value: "publicJudgment",
    valueAlias: "judgment",
    label: "Sentencia - Versión pública",
    isSingleSignable: false,
    isMultipleSignable: false,
    isBulletin: true,
    isJudgmentType: true,
    isReloadComponentByDefault: true,
    getRequiredFields: () => ["document_type", "file"],
    getKeysDataToSend: () => ["document_type", "file", "comment", "expedient_number", "judgment_type", "isPublishedInBulletin"],
    subtypes: judgmentSubtypes,
    getLabelBySubtype: getLabelByJudgmentSubtype,
  },
  {
    value: "notification",
    label: "Notificación",
    isSingleSignable: true,
    isMultipleSignable: true,
    isReloadComponentByDefault: true,
    getRequiredFields: (file = {}) => {
      const commonFields = ["document_type", "file"];
      if (file.electronicNotification === "si")
        return [...commonFields, "email"]
      return commonFields
    },
    getKeysDataToSend: (file) => {
      const commonKeys = ["document_type", "file", "comment", "expedient_number", "check_agreement", "check_judgment", "isPublishedInBulletin"];
      if (file.electronicNotification === "si")
        return [...commonKeys, "email"]
      return commonKeys
    },
  },
  {
    value: "demand",
    label: "Demanda",
    isSingleSignable: true,
    isMultipleSignable: true,
    getRequiredFields: (file = {}) => {
      const commonFields = ["alias", "file", "hasProofs", "promoter"];
      if (file.hasProofs === "si")
        return [...commonFields, "evidence_file"]
      return commonFields
    },
    getKeysDataToSend: () => ["alias", "document_type", "file", "promoter", "hasProofs", "expedient_number", "comment", "isPublishedInBulletin"],
  },
  {
    value: "writ",
    label: "Oficio",
    isSingleSignable: true,
    isMultipleSignable: true,
    isReloadComponentByDefault: true,
    getRequiredFields: () => ["document_type", "file"],
    getKeysDataToSend: () => ["alias", "document_type", "file", "comment", "expedient_number", "isPublishedInBulletin"],
  },
  {
    value: "subpoena",
    label: "Citatorio",
    isSingleSignable: true,
    isMultipleSignable: true,
    isReloadComponentByDefault: true,
    getRequiredFields: () => ["document_type", "file"],
    getKeysDataToSend: () => ["alias", "document_type", "file", "comment", "expedient_number", "isPublishedInBulletin"],
  },
  {
    value: "diligence",
    label: "Acta de diligencia",
    isSingleSignable: true,
    isMultipleSignable: true,
    isReloadComponentByDefault: true,
    getRequiredFields: () => ["document_type", "file"],
    getKeysDataToSend: () => ["alias", "document_type", "file", "comment", "expedient_number", "isPublishedInBulletin"],
  },
  {
    value: "recordAudience",
    label: "Constancia de celebración de audiencia",
    isSingleSignable: true,
    isMultipleSignable: true,
    isReloadComponentByDefault: true,
    getRequiredFields: () => ["document_type", "file"],
    getKeysDataToSend: () => ["alias", "document_type", "file", "comment", "expedient_number", "isPublishedInBulletin"],
  },
  {
    value: "constancy",
    label: "Constancia",
    isSingleSignable: true,
    isMultipleSignable: true,
    isReloadComponentByDefault: true,
    getRequiredFields: () => ["document_type", "file"],
    getKeysDataToSend: () => ["alias", "document_type", "file", "comment", "expedient_number", "isPublishedInBulletin"],
  },
  {
    value: "amparo",
    label: "Demanda de amparo",
    isSingleSignable: true,
    isMultipleSignable: true,
    isReloadComponentByDefault: true,
    getRequiredFields: () => ["document_type", "file"],
    getKeysDataToSend: () => ["alias", "document_type", "file", "comment", "expedient_number", "isPublishedInBulletin"],
  },
  {
    value: "audience",
    label: "Audiencia",
    isSingleSignable: true,
    isMultipleSignable: true,
    isReloadComponentByDefault: true,
    getRequiredFields: () => ["document_type", "file"],
    getKeysDataToSend: () => ["alias", "document_type", "file", "comment", "expedient_number", "isPublishedInBulletin"],
  }
];

export const anexosTypeCatalog = [
  {
    value: "original",
    label: "Original"
  },
  {
    value: "simple",
    label: "Simple"
  },
  {
    value: "certified",
    label: "Certificada"
  }
];

export const getPromotionsWithoutAgreement = async (
  expedientId,
  setPromotions
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/promotions_by_expedient_number/${expedientId}`,
    "get",
    null,
    {}
  );

  const responsePromotions = await request.executeRequest();
  if (
    responsePromotions &&
    responsePromotions.code === 200 &&
    responsePromotions.response.promotions &&
    Object.keys(responsePromotions.response.promotions).length > 0 ){

    const promotionsFormatted = await formattedPromotions(responsePromotions.response.promotions);
    setPromotions(promotionsFormatted);
  }
  else if (
    responsePromotions &&
    responsePromotions.response.promotions &&
    responsePromotions.response.promotions.length === 0 &&
    responsePromotions.response.message &&
    responsePromotions.response.message !== "" &&
    typeof responsePromotions.response.message === "string") {

    setPromotions([]);
  }
  else {

    NotificationManager.error('Ocurrió un error al traer las promociones, inténtalo nuevamente');
    setPromotions([]);
  }
};

export const getAgreementsToNotifyByExpedientId = async (expedientId, notificationType) => {
  const AgreementsToNotifyRequest = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/agreements_or_judgments_by_expedient_id/${expedientId}/${notificationType}`,
    "get",
    null,
    {}
  );

  const AgreementsToNotifyResponse = await AgreementsToNotifyRequest.executeRequest();

  if (
    AgreementsToNotifyResponse &&
    AgreementsToNotifyResponse.code === 200 &&
    AgreementsToNotifyResponse.response.documents &&
    Array.isArray(AgreementsToNotifyResponse.response.documents)) {

    const AgreementsToNotifyResponseFormatted = formattedPromotions(AgreementsToNotifyResponse.response.documents);
    return AgreementsToNotifyResponseFormatted;
  }
  if (
    AgreementsToNotifyResponse &&
    AgreementsToNotifyResponse.response &&
    AgreementsToNotifyResponse.response.message &&
    typeof AgreementsToNotifyResponse.response.message === 'string') {
    NotificationManager.warning(AgreementsToNotifyResponse.response.message);
    return [];
  }
  NotificationManager.error('Ocurrió un error al traer las sentencias o acuerdos a notificar');
  return [];
}

export const formattedPromotions = (
  promotions
) => {

  if(!Array.isArray(promotions)){
    let promotionFormatted = [];
    Object.keys(promotions).forEach((key) => {
      promotionFormatted.push({
        label: promotions[key]['alias'] ? promotions[key]['alias'] : promotions[key]['filename'],
        value: promotions[key]['id']
      });
    });
    return promotionFormatted;
  }

  return promotions.reduce((array, promotion) => {
    const promotionFormatted = {};
    promotionFormatted['label'] = promotion['alias'] ? promotion['alias'] : promotion['filename'];
    promotionFormatted['value'] = promotion['id'];

    array.push(promotionFormatted);
    return array;
  }, []);
};

export const getStringIdFromASelectArray = (selectArray = []) => {
  if (!Array.isArray(selectArray))
    return [];
  return selectArray.map((selectArrayItem) => selectArrayItem.value)
}

export const getSelectedOptionsById = (options = [], selectedOptionsIds = '') => {
  const selectedOptionsIdsArray = Array.isArray(selectedOptionsIds) ? selectedOptionsIds : selectedOptionsIds.split(',');
  const optionsSelectedList = selectedOptionsIdsArray.map((optionId) => {
    return options.find((optionElement) => Number(optionElement.value) === Number(optionId));
  });
  return optionsSelectedList;
}

export const downloadFile = (urlFile, loader) => {
  loader.show(true);
  let a = document.createElement('a');
  a.href = urlFile;
  a.download = true;
  a.target = "_blank";
  a.rel = "noopener noreferrer";

  a.click();
  loader.show(false);
};

export const bodyModalDocuments = (documents) => {
  return (
    <div>
      <table className="table table-collapsed table-bordered mT-10">
        <tbody>
          <tr className="table-documents-modal">
            <th className="text-center">Etiqueta</th>
            <th className="text-center">Tipo</th>
            <th className="text-center">Enlace de descarga</th>
          </tr>
          {
            documents.map((doc, index) => (
              <tr key={index}>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={doc['alias']}>{doc['alias']}</td>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={translateType(doc['document_type'], doc['judgment_type'])}>{translateType(doc['document_type'], doc['judgment_type'])}</td>
                <td style={{ paddingTop: "5px" }} className="text-center">
                  <a download target="_blank" rel="noopener noreferrer" href={doc['file_path'] + "?token=" + process.env.REACT_APP_TOKEN}>
                    <Button
                      variant="primary"
                      style={{
                        marginLeft: "10px",
                        fontSize: "0.85rem"
                      }}
                      size="sm"
                    >
                      Archivo digital <i className="ti-download" title={"Descargar " + doc['alias']} />
                    </Button>
                  </a>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export const bodyModalExpiration = (documents) => {
  return (
    <div>
      <table className="table table-collapsed table-bordered mT-10">
        <tbody>
          <tr className="table-documents-modal">
            <th className="text-center">Nombre</th>
            <th className="text-center">Etiqueta</th>
            <th className="text-center">Vence</th>
            <th className="text-center">Indicador</th>
          </tr>
          {
            documents.map((doc, index) => (
              <tr key={index}>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={doc['name']}>{doc['name']}</td>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={doc['alias']}>{doc['alias']}</td>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={doc['expired_at']}>{doc['expired_at']}</td>
                <td style={{ paddingTop: "5px" }} className="text-center"><span className={"dot-" + doc['warning_flag']} /></td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export const footerModalDocuments = (setCloseModal) => {
  return (
    <>
      <Button variant="secondary" onClick={() => setCloseModal()} >Cerrar</Button>
    </>
  );
};

const translateType = (documentType, judgmentType) => {
  switch (documentType) {
    case "agreement":
      return 'Acuerdo';

    case "promotion":
      return 'Promoción';

    case "judgment":
      const judgment_type = judgmentType == 'interlocutory' ? ' Interlocutoria' : judgmentType == 'definitive' ? ' Definitiva' : '';
      return 'Sentencia' + judgment_type;

    case "notification":
      return 'Notificación';

    case "annexe":
      return 'Anexo';

    case "demand":
      return 'Demanda';

    default:
      return documentType;
  }
}

export const optionsDocument = [
  {
    value: 'Copia simple',
    label: 'Copia simple'
  },
  {
    value: 'Copia certificada',
    label: 'Copia certificada'
  },
  {
    value: 'Original',
    label: 'Original'
  }
];

export const bodyModalAddEvidenceFile = (
  setFileState, fileState, getRootProps,
  errors, setErrors, getInputProps
) => {

  return (
    <div>
      <Form>

        <Form.Group>
          <Form.Label>* Tipo de documento:</Form.Label>

          <Select
            options={optionsDocument}
            onChange={async (selected) => {

              setFileState({ ...fileState, evidence_type: selected["value"] });
              setErrors({ ...errors, evidence_type: [] });
            }}
            placeholder="Selecciona el tipo de documento"
            styles={selectStylesDefault}
          />
          {renderErrorsByInputName(errors, "evidence_type")}
        </Form.Group>

        <Form.Group>
          <Form.Label>* Etiqueta:</Form.Label>
          <Form.Control
            onChange={(e) => {
              setErrors({ ...errors, alias: [] });
              setFileState({ ...fileState, alias: e.target.value });
            }}
            placeholder="Agrega una etiqueta para identificar este documento"
          />
          {renderErrorsByInputName(errors, "alias")}
        </Form.Group>

        <Form.Group>
          <TextTooltip text={'Evitar archivos con caracteres especiales: puntos, guion bajo, barra inclinada, dos puntos, signos etc.'}>
            <Form.Label>* Anexo:</Form.Label>
          </TextTooltip>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Button style={{ fontWeight: "bold", fontSize: "14px" }} variant="primary" size="md" block>
                * Selecciona el anexo a subir
              </Button>
              <div className="classHelperContextual" >
                <small>El anexo deber ser de tipo PDF, PNG Y JPG</small>
              </div>
            </div>
          </section>
          {renderErrorsByInputName(errors, "evidence_file")}

          {
            fileState["evidence_file"] && fileState["evidence_file"]["preview"] ?
              <>
                <div
                  className={fileState["evidence_file"] && fileState["evidence_file"]["preview"] ? "container-evidence-file" : "d-none"}
                >
                  <img
                    src={fileState["evidence_file"]["preview"]}
                    alt="Imagen"
                    width="50%"
                  />
                </div>
                <small style={{ float: "right" }}>{fileState["evidence_file"]["name"]}</small>
                <br />
              </> :
              ""
          }
        </Form.Group>

      </Form>
    </div>
  );
};

export const getEvidencesDocument = async (
  documentId,
  setDocumentsData,
  setShowDocuments
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/evidences/retrieve/${documentId}`,
    "get",
    null,
    {}
  );

  const responseEvidences = await request.executeRequest();
  if (
    responseEvidences &&
    responseEvidences.code === 200 &&
    responseEvidences.response &&
    responseEvidences.response.evidences
  ) {
    const { evidences } = responseEvidences.response;
    setDocumentsData(evidences);
    setShowDocuments(true);
  }
  else {
    setDocumentsData([]);
    setShowDocuments(true);
    NotificationManager.info(
      'No se encontraron anexos en esta promoción',
      '',
      3500
    );
  }
}

export const getAgreementTypes = async (
  setAgreementTypes
) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/catalogs/headings`,
    "get",
    null,
    {}
  );

  const responseAgreementTypes = await request.executeRequest();

  if (
    responseAgreementTypes &&
    responseAgreementTypes.code === 200 &&
    responseAgreementTypes.response &&
    responseAgreementTypes.response.headings
  ) {
    let { headings } = responseAgreementTypes.response;
    headings = formattedHeadings(headings);
    setAgreementTypes(headings);
  } else {
    NotificationManager.error(
      'No fue posible recuperar los rubros para la creación de este acuerdo',
      3500
    );
    setAgreementTypes([{ value: '', label: 'No existen rubros disponibles' }]);
  }
};

export const formattedHeadings = (headings) => {

  return headings.reduce((newArr, heading) => {
    let newHeading = {};
    newHeading['label'] = heading['name'];
    newHeading['value'] = heading['id'];

    newArr.push(newHeading);

    return newArr;
  }, []);
};

export const getTemplatesByType = async (
  type,
  setTemplates,
  expedientId,
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_template/${type}/${expedientId}`,
    "get",
    null,
    {}
  );

  const responseTemplates = await request.executeRequest();
  type = type + 's';
  if (
    responseTemplates &&
    responseTemplates.code === 200 &&
    responseTemplates.response &&
    responseTemplates.response[type] &&
    Object.keys(responseTemplates.response[type]).length > 0
  ) {
    let templates = [];
    for (let template in responseTemplates.response[type]) {
      responseTemplates.response[type][template]['html'] = responseTemplates.response[type][template]['html'].replace(/\/?h[1-6]/ig, 'p');
      responseTemplates.response[type][template]['html'] = responseTemplates.response[type][template]['html'].replace(/font-size/g, 'color');
      templates.push(responseTemplates.response[type][template]);
    }
    setTemplates(templates);
  }
  else {
    setTemplates([]);
    NotificationManager.warning(
      `No se han encontrado plantillas de tipo ${type === 'agreements' ? 'acuerdo:' :
        type === 'notifications' ? 'notificación:' :
          type === 'emails' ? 'correo electrónico:' :
            type === 'promotions' ? 'promoción:' : 'sentencia:'
      }`,
      '',
      5000
    );
  }
};

export const getTemplatesPresaved = async (
  expedientId,
  setTemplatesAgreement,
  setTemplatesNotification,
  setTemplatesWrit,
  setTemplatesEmail,
  setTemplatesJudgment,
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_expedient_template/${expedientId}`,
    "get",
    null,
    {}
  );
  const responseTemplates = await request.executeRequest();
  if (
    responseTemplates &&
    responseTemplates.code === 200 &&
    responseTemplates.response &&
    responseTemplates.response.Templates &&
    (responseTemplates.response.Templates.Agreements ||
      responseTemplates.response.Templates.Notifications ||
      responseTemplates.response.Templates.Emails ||
      responseTemplates.response.Templates.Judgments ||
      responseTemplates.response.Templates.Promotions ||
      responseTemplates.response.Templates.Writs)
  ) {

    if (responseTemplates.response.Templates.Agreements) setTemplatesAgreement(
      responseTemplates.response.Templates.Agreements
    )
    else setTemplatesAgreement([]);
    if (responseTemplates.response.Templates.Notifications) setTemplatesNotification(
      responseTemplates.response.Templates.Notifications
    )
    else setTemplatesNotification([]);
    if (responseTemplates.response.Templates.Emails) setTemplatesEmail(
      responseTemplates.response.Templates.Emails
    )
    else setTemplatesEmail([]);
    if (responseTemplates.response.Templates.Judgments) setTemplatesJudgment(
      responseTemplates.response.Templates.Judgments
    )
    else setTemplatesJudgment([]);

    if (responseTemplates.response.Templates.Writs) setTemplatesWrit(
      responseTemplates.response.Templates.Writs
    )
    else setTemplatesWrit([]);

  } else {
    setTemplatesNotification([]);
    setTemplatesAgreement([]);
    setTemplatesEmail([]);
    setTemplatesJudgment([]);
    setTemplatesWrit([]);
  }
};

export const requestTemplatePresave = async (
  template,
  setErrors,
  setReloadDataTemplates,
  reloadDataTemplates,
  setShowModalTiny,
  setShowModalTemplates
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/set_expedient_template`,
    "post",
    null,
    { ...template }
  );

  const responsePresaveTemplates = await request.executeRequest();
  if (
    responsePresaveTemplates &&
    responsePresaveTemplates.code === 422 &&
    responsePresaveTemplates.response.error &&
    typeof responsePresaveTemplates.response.message === 'object'
  ) {
    setErrors(responsePresaveTemplates.response.message);
    NotificationManager.error('¡Existen errores, revisa los campos marcados en rojo y vuelve a intentarlo!');
  } else if (
    responsePresaveTemplates &&
    responsePresaveTemplates.code === 200 &&
    !responsePresaveTemplates.response.error &&
    responsePresaveTemplates.response.message &&
    typeof responsePresaveTemplates.response.message === 'string' &&
    responsePresaveTemplates.response.newExpedientTemplate
  ) {
    setReloadDataTemplates(!reloadDataTemplates);
    setShowModalTiny(false);
    setShowModalTemplates(false);
    NotificationManager.success(`El proyecto de ${template.name.toLowerCase()} ha sido guardado correctamente`);
    return responsePresaveTemplates.response.newExpedientTemplate;
  } else {
    NotificationManager.error('¡Ha ocurrido un error inesperado y el template no fue creado, inténtalo nuevamente!');
  }
};

const getAgreementsByHeadings = (headings, agreementTypes) => {
  if (!Array.isArray(agreementTypes) || !Array.isArray(headings)) {
    return []
  }
  if (!headings.length) {
    return []
  }
  return agreementTypes.filter((agragreement) => headings.includes(agragreement.value))
}

export const getUserTemplateById = async (
  templateId,
  setTemplate,
  setOpenModal,
  agreementTypes
) => {
  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_expedient_user_templates/${userId_03}/${templateId}`,
    "get",
    null,
    {}
  );

  const responseUserTemplate = await request.executeRequest();
  if (
    responseUserTemplate &&
    responseUserTemplate.code === 200 &&
    responseUserTemplate.response &&
    responseUserTemplate.response.ExpedientTemplates &&
    responseUserTemplate.response.ExpedientTemplates[0]
  ) {
    const template = responseUserTemplate.response.ExpedientTemplates[0];
    const promotions = template['promotions'].map((p) => ({ label: p['alias'], value: p['id'] }));
    const defaultAgreements = getAgreementsByHeadings(template.headings, agreementTypes);
    let templateDataToSet = {
      id: template['id'],
      alias: template['alias'],
      headings: template['headings'],
      defaultAgreements: defaultAgreements,
      expedient: template['expedient'],
      type: template['type'],
      last_comment: template['comments'],
      comments: '',
      comment: Boolean(template['comment']) ? template['comment'] : '',
      filename: template['filename'],
      content: template['content'],
      body: template['content'],
      answer_promotion: template['answer_promotion'],
      promotions: promotions,
      user_id: template['user_id'],
      email_template_id: template['email_template_id'] || null,
      agreementsOrJudgmentsToNotify: template['agreements_or_judgments'],
      excerpt: template.excerpt,
      ifexcerpt: Boolean(template['excerpt']),
      bulletin: template['bulletin']
    }
    setTemplate(templateDataToSet);
    setOpenModal(true);
  } else {
    setTemplate({});
    NotificationManager.error('No fue posible encontrar la plantilla corespondiente');
  }
};

export const requestSendUpdateTemplate = async (
  template, setShowModalTiny,
  setReloadData, reloadData
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/update_content_expedient_template`,
    "put",
    null,
    { ...template }
  );

  const responseUpdateTemplate = await request.executeRequest();
  if (
    responseUpdateTemplate &&
    responseUpdateTemplate.code === 200 &&
    responseUpdateTemplate.response &&
    !responseUpdateTemplate.response.error &&
    responseUpdateTemplate.response.expedientTemplate &&
    typeof responseUpdateTemplate.response.expedientTemplate === 'object'
  ) {
    const type = responseUpdateTemplate.response.expedientTemplate.type;
    setReloadData(!reloadData);
    NotificationManager.success(
      type === 'agreement' ? 'El proyecto de acuerdo ha sido actualizado correctamente' :
        type === 'notification' ? 'El proyecto de notificación ha sido actualizado correctamente' :
          type === 'judgment' ? 'El proyecto de sentencia ha sido actualizado correctamente' :
            type === 'email' ? 'El proyecto de correo ha sido actualizado correctamente' :
              'El proyecto de promoción ha sido actualizado correctamente',
      '', 5000
    );
    setShowModalTiny(false);
  } else {
    NotificationManager.error(
      'Ha ocurrido un error desconocido al actualizar la plantilla, inténtalo nuevamente...',
      '',
      5000
    );
  }
};

export const bodyDeleteTemplate = (
  <div>
    <b>¿Estás seguro de eliminar este elemento? Esta acción es irreversible</b>
  </div>
);

export const requestDeleteTemplate = async (
  templateId, setOpenDeleteTemplate,
  setReloadData, reloadData
) => {
  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/unsigned_documents/${userId_03}/${templateId}`,
    "delete",
    null,
    {}
  );

  const responseDeleteTemplate = await request.executeRequest();
  if (
    responseDeleteTemplate.code === 200 &&
    responseDeleteTemplate.response.error === false
  ) {
    setReloadData(!reloadData);
    NotificationManager.success(
      responseDeleteTemplate.response.message || 'El elemento ha sido eliminado correctamente',
      '',
      4500
    );
    setOpenDeleteTemplate(false);
  } else {
    NotificationManager.warning(
      'No es posible eliminar la plantilla, ya que ha sido creada por otro usuario.',
      '',
      4500
    );
  }
};

export const getCommentsByTemplate = async (
  templateId, setComments, setOpenComments
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_expedient_template_comments/${templateId}`,
    "get",
    null,
    {}
  );
  const responseComments = await request.executeRequest();
  if (
    responseComments && responseComments.code === 200 &&
    responseComments.response && responseComments.response.comments
  ) {
    setComments(responseComments.response.comments);
    setOpenComments(true);
  } else {
    setComments([]);
    setOpenComments(false);
  }
};

export const requestUnsignPDFTemplate = async (
  
  pdfTemplate, setShowModalTiny,
  setReloadData, reloadData,
  setErrors, reloadParentComponent,
  setDataUploadEvidences, setShowConfirmUploadModal
) => {
  const urlpdfRequest = `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/template_pdf`;
  const newDocumentRequest = [urlpdfRequest,"post", null, pdfTemplate];
  const responsePDFTemplate = await handleRequest(...newDocumentRequest);
  
  if (
    responsePDFTemplate &&
    responsePDFTemplate.code === 200 &&
    responsePDFTemplate.response &&
    typeof responsePDFTemplate.response === 'object'
  ) {
    setShowConfirmUploadModal(false);
    if (pdfTemplate['type'] === 'promotion' && pdfTemplate['hasProofs'] === 'si') {
      setDataUploadEvidences({
        promotion_id: responsePDFTemplate.response.promotion_id,
        documentUpload: responsePDFTemplate.response,
        change: new Date().getTime()
      });
    } else {
      setReloadData(!reloadData);
      await reloadParentComponent(
        '¡El documento ha sido creado correctamente!',
        '',
        4500
      );
      setShowModalTiny(false);
    }
  } else {
    if (
      responsePDFTemplate &&
      responsePDFTemplate.code === 422 &&
      responsePDFTemplate.response &&
      responsePDFTemplate.response.error &&
      typeof responsePDFTemplate.response.message === 'object'
    ) {
      const keysErrors = Object.keys(responsePDFTemplate.response.message);
      setErrors(responsePDFTemplate.response.message);
      NotificationManager.error(
        responsePDFTemplate.response.message[keysErrors[0]][0],
        'Revisa los errores marcados en rojo:',
        4500
      );
    } else {
      NotificationManager.error(
        'Ha ocurrido un error desconocido, vuelve a intentarlo...',
        '',
        4500
      );
    }
  }
};

export const requestCreatePDFTemplate = async (
  pdfTemplate, setShowModalTiny,
  setReloadData, reloadData,
  setErrors, reloadParentComponent,
  setDataUploadEvidences, animateScroll
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/template_pdf`,
    "post",
    null,
    { ...pdfTemplate }
  );
  const responsePDFTemplate = await request.executeRequest();
  if (
    responsePDFTemplate &&
    responsePDFTemplate.code === 200 &&
    responsePDFTemplate.response &&
    typeof responsePDFTemplate.response === 'object'
  ) {
    if (pdfTemplate['type'] === 'promotion' && pdfTemplate['hasProofs'] === 'si') {
      setDataUploadEvidences({
        promotion_id: responsePDFTemplate.response.promotion_id,
        documentUpload: responsePDFTemplate.response,
        change: new Date().getTime()
      });
    } else {
      setReloadData(!reloadData);
      await reloadParentComponent(
        '¡El documento ha sido creado correctamente!',
        '',
        4500
      );
      setShowModalTiny(false);
    }
  } else {
    if (
      responsePDFTemplate &&
      responsePDFTemplate.code === 422 &&
      responsePDFTemplate.response &&
      responsePDFTemplate.response.error &&
      typeof responsePDFTemplate.response.message === 'object'
    ) {
      const keysErrors = Object.keys(responsePDFTemplate.response.message);
      setErrors(responsePDFTemplate.response.message);
      NotificationManager.error(
        responsePDFTemplate.response.message[keysErrors[0]][0],
        'Revisa los errores marcados en rojo:',
        4500
      );
    } else {
      NotificationManager.error(
        'Ha ocurrido un error desconocido, vuelve a intentarlo...',
        '',
        4500
      );
    }
  }
};

export const requestCommentAndRelease = async (
  templateId,
  data,
  props
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/return_to_correction/${templateId}`,
    "post",
    null,
    { ...data }
  );
  const responseCommentAndRelease = await request.executeRequest();
  if (
    responseCommentAndRelease &&
    responseCommentAndRelease.code === 200 &&
    responseCommentAndRelease.response &&
    responseCommentAndRelease.response.governmentBook
  ) {
    NotificationManager.success(
      '',
      `El ${getLabelFromDictionary('global', 'lowerMainName')} ha sido devuelto para correción`,
      4500
    );
    props.history.push('/pending_transfers');
  } else {
    NotificationManager.error(
      '',
      `Ha ocurrido un error desconocido al intentar devolver este ${getLabelFromDictionary('global', 'lowerMainName')} para corrección`,
      4500
    );
  }
};

export const bodyDocumentsView = (
  currentDocument, setCurrentDocument,
  documentsView, pause, setPause
) => {
  return (
    documentsView.length > 0 &&
    <div
      onMouseOut={() => setPause(true)}
      onMouseOver={() => setPause(false)}
    >
      <Carousel
        pause='hover'
        activeIndex={currentDocument}
        onSelect={(selected) => {
          if (!pause) setCurrentDocument(selected)
        }}
        indicators={false}
      >
        {documentsView.map((doc, i) => (
          <Carousel.Item interval={10000} key={i}>
            <div className="document-pdf-expedients" style={{ minHeight: '70vh', height: 'auto' }}>
              {doc['extension'] === 'pdf' ?
                <RenderPdfDocument
                  doc={doc['file_path']}
                  currentDocument={documentsView[currentDocument]['file_path']}
                />
                :
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{ width: 'auto', maxWidth: '70vw', marginTop: '10vh', marginBottom: '10vh' }}
                    src={doc['file_path']}
                    alt={doc.alias}
                  />
                </div>
              }
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export const BodyTransfer = ({
  dataTransfer,
  setDataTransfer,
  usersOptions,
  dependencesOptions,
  errors = {},
  setErrors = () => { },
  canTurnForeign,
  isAnExternalTransfer,
  isASupremeCourtOfJusticeReceiver,
  canCreateToca,
  isATransferOfTypeCreateToca,
  expedientParts,
  isAnAppealTypeProcedure,
}) => {

  const textForLegalActorRepresentative = useLegalRepresentativeText(dataTransfer.actor_regime);
  const textForLegalDefendantRepresentative = useLegalRepresentativeText(dataTransfer.defendant_regime);

  const usersOptionsFiltered = usersOptions.filter((u) =>
    Number(u.value) !== Number(Cookies.get('userId_03')));

  const matterSelectorOptions = [{ value: dataTransfer.matter, label: dataTransfer.matter }];

  const removeError = (errorName) => {
    const newErrors = _.omit(errors, errorName);
    setErrors(newErrors);
  }

  const onTransferTypeTabsChange = (selectedTab) => {
    const isTheExternalTabSelected = (selectedTab === 'external');

    const newSetDataTransferValue = {
      ...dataTransfer,
      type: selectedTab,
      external: isTheExternalTabSelected,
      receiver: null,
      parties_array: expedientParts,
    }

    setDataTransfer(newSetDataTransferValue);
    removeError('type');
  }

  const onSelectChange = (selected, selectName = '') => {
    setDataTransfer({
      ...dataTransfer,
      [selectName]: selected
    });
    removeError(selectName);
  }

  const defaultActiveTab = isAnExternalTransfer ? 'external' : '';

  const {
    selectCourtsOptions,
    selectCurtUsersOptions,
    handleGetSelectCurtUsersOptions,
  } = useCreateTocaState({ isCreateTocaTabSelected: isATransferOfTypeCreateToca });

  const handleSelectRoomChange = (selected) => {
    const courtId = selected.value;
    onSelectChange(selected, 'room');
    handleGetSelectCurtUsersOptions(courtId);
  }

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Label>
            <b style={errors['type'] ? { color: 'red' } : null}>
              * Elige que tipo de turnado deseas hacer
            </b>
          </Form.Label>
          <Tabs defaultActiveKey={defaultActiveTab} id='transfer-type-tabs' onSelect={onTransferTypeTabsChange}>
            <Tab tabClassName='text-body' eventKey='internal' title='Turnado interno' />

            {
              canTurnForeign
              &&
              <Tab tabClassName='text-body' eventKey='external' title='Turnado externo' />
            }

            {
              canCreateToca
              &&
              <Tab tabClassName='text-body' eventKey='create_toca' title='Crear TOCA' />
            }
          </Tabs>
          {renderErrorsByInputName(errors, 'type')}
        </Form.Group>

        {
          dataTransfer['type'] === 'internal' ?
            <Form.Group>
              <Form.Label>
                <b style={errors['receiver'] || errors['receiver_id'] || errors['dependence_id'] ? { color: 'red' } : null}>
                  * Elige el usuario al que turnaras este {getLabelFromDictionary('global', 'lowerMainName')}
                </b>
              </Form.Label>
              <Select
                options={usersOptionsFiltered}
                onChange={(selected) => onSelectChange(selected, 'receiver')}
                placeholder="Da clic y elige algún usuario"
                styles={errors['receiver'] || errors['receiver_id'] || errors['dependence_id'] ?
                  selectStylesError : selectStylesDefault}
                value={dataTransfer.receiver}
                classNamePrefix="select-receiver"
              />
              {renderErrorsByInputName(
                errors,
                errors['receiver'] ? 'receiver' : errors['receiver_id'] ? 'receiver_id' : 'dependence_id'
              )}
            </Form.Group> : isAnExternalTransfer &&
            <>
              <Form.Group>
                <Form.Label>
                  <b style={errors['receiver'] || errors['receiver_id'] || errors['dependence_id'] ? { color: 'red' } : null}>
                    * Elige la dependencia a la que turnaras este {getLabelFromDictionary('global', 'lowerMainName')}
                  </b>
                </Form.Label>
                <Select
                  options={dependencesOptions}
                  onChange={(selected) => onSelectChange(selected, 'receiver')}
                  placeholder="Da clic y elige alguna dependencia"
                  styles={errors['receiver'] || errors['receiver_id'] || errors['dependence_id'] ?
                    selectStylesError : selectStylesDefault}
                  value={dataTransfer.receiver}
                />
                {renderErrorsByInputName(
                  errors,
                  errors['receiver'] ? 'receiver' : errors['receiver_id'] ? 'receiver_id' : 'dependence_id'
                )}
              </Form.Group>

              {
                isASupremeCourtOfJusticeReceiver
                &&
                <>
                  <Form.Group>
                    <Form.Label>
                      <b style={errors.procedure_type ? { color: 'red' } : null}>
                        * Elige el tipo de trámite
                      </b>
                    </Form.Label>
                    <Select
                      options={procedureTypeOptions}
                      onChange={(selected) => {
                        delete errors.procedure_type;
                        setDataTransfer({
                          ...dataTransfer,
                          procedure_type: selected,
                        })
                      }}
                      placeholder="Da clic y elige algún tipo de trámite"
                      styles={errors.procedure_type ? selectStylesError : selectStylesDefault}
                      value={_.get(dataTransfer, 'procedure_type', null)}
                    />
                    {renderErrorsByInputName(errors, errors.procedure_type)}
                  </Form.Group>

                  {
                    isAnAppealTypeProcedure
                    &&
                    <Form.Group>
                      <Form.Label>
                        <b style={errors.appellant_full_name ? { color: 'red' } : null}>
                          * Nombre completo del Apelante:
                        </b>
                      </Form.Label>
                      <Form.Control
                        isInvalid={Boolean(errors.appellant_full_name)}
                        placeholder="Ingresa el nombre completo del apelante"
                        value={_.get(dataTransfer, 'appellant_full_name', '')}
                        name={'appellant_full_name'}
                        onChange={({ target }) => {
                          delete errors.appellant_full_name;
                          setDataTransfer({
                            ...dataTransfer,
                            appellant_full_name: target.value,
                          })
                        }}
                      />
                      {renderErrorsByInputName(errors, errors.appellant_full_name)}
                    </Form.Group>
                  }
                </>
              }
            </>
        }

        {
          isATransferOfTypeCreateToca
          &&
          <>
            <Form.Group>
              <Form.Label>
                <b style={errors.room ? { color: 'red' } : null}>
                  * Sala
                </b>
              </Form.Label>
              <Select
                options={selectCourtsOptions}
                onChange={handleSelectRoomChange}
                placeholder="Da clic y elige una sala"
                styles={errors.room ? selectStylesError : selectStylesDefault}
                value={dataTransfer.room}
                classNamePrefix="select-room"
              />
              {renderErrorsByInputName(errors, 'room')}
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <b style={errors.receiverUser ? { color: 'red' } : null}>
                  * Usuario Receptor
                </b>
              </Form.Label>
              <Select
                options={selectCurtUsersOptions}
                onChange={(selected) => onSelectChange(selected, 'receiverUser')}
                placeholder="Da clic y elige un usuario receptor"
                styles={errors.receiverUser ? selectStylesError : selectStylesDefault}
                value={dataTransfer.receiverUser}
                classNamePrefix="select-receiver-user"
                isDisabled={!dataTransfer.room}
              />
              {renderErrorsByInputName(errors, 'receiverUser')}
            </Form.Group>
          </>
        }

        {
          Boolean(dataTransfer.type)
          &&
          <>
            <Form.Group className={dataTransfer['expedient_number'] ? '' : 'd-n'}>
              <Form.Label>
                <b>{getLabelFromDictionary('addExpediente', 'numberExpedient')}</b>
              </Form.Label>
              <Form.Control
                disabled
                value={dataTransfer["expedient_number"] ? dataTransfer["expedient_number"] : ""}
                aria-label="numero de expediente"
                placeholder={`Número del ${getLabelFromDictionary('global', 'lowerMainName')}`}
              />
            </Form.Group>

            {
              Boolean(expedientParts.length)
              &&
              <Form.Group>
                <PartsRelatedToTheExpedientTable expedientParts={expedientParts} />
              </Form.Group>
            }

            <Form.Group className={dataTransfer['kind_judgment'] ? '' : 'd-n'}>
              <Form.Label>
                <b>{getLabelFromDictionary('turned', 'trialType')}:</b>
              </Form.Label>
              <Select
                isDisabled
                options={[]}
                placeholder={getLabelFromDictionary('turned', 'trialType')}
                defaultValue={dataTransfer["kind_judgment"] ? dataTransfer["kind_judgment"] : null}
                styles={selectStylesDefault}
              />
            </Form.Group>

            <Form.Group className={dataTransfer['via'] ? '' : 'd-n'}>
              <Form.Label>
                <b>{getLabelFromDictionary('turned', 'via')}:</b>
              </Form.Label>
              <Select
                isDisabled
                options={[]}
                placeholder={getLabelFromDictionary('turned', 'via')}
                defaultValue={dataTransfer["via"] ? dataTransfer["via"] : ""}
                styles={selectStylesDefault}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <b>{getLabelFromDictionary('turned', 'subject')}:</b>
              </Form.Label>
              <Select
                isDisabled
                options={matterSelectorOptions}
                placeholder=""
                value={matterSelectorOptions.find((option) => option.value === dataTransfer.matter)}
                styles={selectStylesDefault}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <b style={errors.observation ? { color: 'red' } : null}>
                  {isATransferOfTypeCreateToca && '*'} Observaciones :
                </b>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="5"
                aria-label="Observaciones"
                className={errors.observation ? 'is-invalid' : ''}
                placeholder="Aquí puedes agregar algunas observaciones si lo deseas"
                onChange={(e) => setDataTransfer({
                  ...dataTransfer,
                  observation: e.target.value
                })}
              />
              <Form.Text>
                En caso de tener observaciones, captúralas aquí de manera clara y breve
              </Form.Text>
              {renderErrorsByInputName(errors, 'observation')}
            </Form.Group>
          </>
        }
      </Form>
    </div>
  );
};

export const footerDocumentsView = (setCloseModal) => {
  return (
    <>
      <Button variant="secondary" onClick={() => setCloseModal()}>Cerrar</Button>
    </>
  );
};

export const getAllDocumentsOfExpedient = async (
  expedientId, setDocumentsView, setShow
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_all_documents/${expedientId}`,
    "get",
    null,
    {}
  );
  const responseDocumentsExpedient = await request.executeRequest();
  if (
    responseDocumentsExpedient && responseDocumentsExpedient.code === 200 &&
    responseDocumentsExpedient.response && responseDocumentsExpedient.response.documents &&
    responseDocumentsExpedient.response.documents.length > 0
  ) {
    setDocumentsView(responseDocumentsExpedient.response.documents);
    setShow(true);
  } else if (
    responseDocumentsExpedient && responseDocumentsExpedient.code === 404
  ) {
    NotificationManager.info(responseDocumentsExpedient.response.message, 'Atención:', 5000);
  } else {
    NotificationManager.error(`Ha ocurrido un error y no fue posible traer los documentos del ${getLabelFromDictionary('global', 'lowerMainName')}...`);
  }
};

export const footerTransfer = (handleClose, handleSubmit) => {
  return (
    <div>
      <Button
        variant="secondary"
        className="mR-10"
        onClick={() => handleClose()}
      >
        Cerrar
      </Button>
      <Button
        variant="primary"
        onClick={() => handleSubmit()}
      >
        Transferir
      </Button>
    </div>
  );
};

export const getDependencesToTransfer = async (setDependences) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/catalogs/dependences`,
    "get",
    null,
    {}
  );
  const responseDependences = await request.executeRequest();
  if (
    responseDependences && responseDependences.code === 200 &&
    responseDependences.response && responseDependences.response.dependences &&
    responseDependences.response.dependences.length > 0
  ) setDependences(responseDependences.response.dependences.map((dep) => ({
    label: dep.name,
    value: dep.id,
  })));
};

export const handleTransferExpedient = async (
  data,
  setErrors
) => {
  setErrors({});
  let partiesList = await getPartiesByExpedientId(data.id);
  data.parties_array = partiesList;

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/release`,
    "post",
    null,
    {
      ...data,
      force: false
    }
  );
  const responseTransfer = await request.executeRequest();
  if (
    responseTransfer && responseTransfer.code === 200 &&
    responseTransfer.response && responseTransfer.response.governmentBook &&
    typeof responseTransfer.response.governmentBook === 'object'
  ) {
    NotificationManager.success(
      `El ${getLabelFromDictionary('global', 'lowerMainName')} ha sido transferido correctamente`,
      '',
      6000
    );

    return true

  } else {
    if (
      responseTransfer && responseTransfer.code === 422 &&
      typeof responseTransfer.response.message === 'object'
    ) {
      setErrors(responseTransfer.response.message);
      NotificationManager.error(
        'Existen errores que impiden continuar, revisa los datos que has ingresado',
        '',
        5000
      );

      return

    } else {
      NotificationManager.error(
        'Existen errores que impiden continuar, revisa los datos que has ingresado',
        '',
        5000
      );

      return

    }
  }
};

export const getEmailsTemplates = async (expedientId, setEmailsOptions) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_expedient_template_emails/${expedientId}`,
    "get",
    null,
    {}
  );
  const responseEmailsTemplates = await request.executeRequest();
  if (
    responseEmailsTemplates &&
    responseEmailsTemplates.code === 200 && responseEmailsTemplates.response &&
    responseEmailsTemplates.response.emails && responseEmailsTemplates.response.emails.length > 0
  ) {
    setEmailsOptions(responseEmailsTemplates.response.emails.map((e) => ({
      label: e.alias,
      value: e.id
    })));
  }
};

export const getExpedientStakeHolders = async (expedientId, setUsers) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedient/stakeholders/${expedientId}`,
    "get",
    null,
    {}
  );
  const responseUsers = await request.executeRequest();
  if (
    responseUsers &&
    responseUsers.code === 200 && responseUsers.response &&
    responseUsers.response.users && responseUsers.response.users.length > 0
  ) {
    setUsers(responseUsers.response.users.map((u) => ({
      ...u,
      canDelete: true
    })));
  }
};

export const bodyNotifyEmail = (users, setUsers, newUser, setNewUser) => {
  const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isEmailValid = regexEmail.test(newUser.email);
  const isNameValid = newUser.fullname.trimStart() !== '';
  const canAddUser = isNameValid && isEmailValid;

  const addNewUser = () => {
    if (canAddUser) {
      let currentUsers = [...users];
      currentUsers.unshift(newUser);
      setUsers(currentUsers);
      setNewUser({
        fullname: '',
        email: '',
        id: null,
        canDelete: true
      });
    }
  };

  const handleDeletItem = (indexToDelete) => {
    let currentUsers = [...users];
    currentUsers.splice(indexToDelete, 1);
    setUsers(currentUsers);
  };

  return (
    <div>
      <section className="container">
        <h4>
          <b>
            Aquí puedes agregar algún contacto manualmente:
          </b>
        </h4>
        <div style={{ float: 'right', marginTop: '-20px' }}>
          <Button
            style={{ fontWeight: "bold", fontSize: "14px", backgroundColor: "#02cc98" }}
            size="md"
            variant="outline-light"
            disabled={!canAddUser}
            onClick={() => addNewUser()}
          >
            <strong>
              <i className="fas fa-plus" />{'  '}
              Agregar
            </strong>
          </Button>
        </div>
        <div className="clearFloat" />
        <div className="mT-10" style={{ marginBottom: '-20px' }}>
          <Form>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>
                    <b>Nombre Completo:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Agrega el nombre completo"
                    onChange={(e) => setNewUser({ ...newUser, fullname: e.target.value })}
                    value={newUser.fullname}
                  />
                  <Form.Text
                    style={newUser.fullname !== '' && !isNameValid ? { color: 'red' } : null}
                  >
                    {newUser.fullname !== '' && !isNameValid ?
                      'Aún no has escrito nada...' :
                      'Agrega el nombre completo de quien se notificará'}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>
                    <b>Correo Electrónico:</b>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Agrega un correo electrónico"
                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                    value={newUser.email}
                  />
                  <Form.Text
                    style={newUser.email !== '' && !isEmailValid ? { color: 'red' } : null}
                  >
                    {newUser.email !== '' && !isEmailValid ?
                      'Ingresa un correo electrónico válido...' :
                      'Agrega el correo electrónico de quien se notificará'}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
        <div style={{ overflowX: 'scroll' }}>
          <hr />
          <table
            className="table table-collapsed"
            style={{
              border: '1px solid #a2a2a2'
            }}>
            <tbody>
              <tr style={{ backgroundColor: '#02cc98' }}>
                <th className="text-center button-see-evidence-documents">Nombre:</th>
                <th className="text-center button-see-evidence-documents">Correo:</th>
                <th className="text-center button-see-evidence-documents">Acciones:</th>
              </tr>
              {users.map((u, i) => (
                <tr key={i}>
                  <td className="text-center">
                    {u.fullname}
                  </td>
                  <td className="text-center">
                    {u.email}
                  </td>
                  <td className="text-center">
                    {u.canDelete ?
                      <Button
                        size="sm"
                        variant="danger"
                        title="Borrar elemento"
                        onClick={() => handleDeletItem(i)}
                      >
                        <i className="ti-trash" />
                      </Button> : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export const footerNotifyEmail = (handleClose, handleRequestInsert) => {
  return (
    <>
      <Button
        variant="secondary"
        className="mR-10"
        onClick={() => handleClose()}
      >
        Cancelar
      </Button>
      <Button
        variant="primary"
        onClick={() => handleRequestInsert()}
      >
        Notificar
      </Button>
    </>
  );
};

export const requestCreatePDFTemplateOther = async (
  pdfTemplate, setErrors, dataNotificationEmail,
  reloadParentComponent, reloadDataTemplates
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/template_pdf`,
    "post",
    null,
    { ...pdfTemplate }
  );

  const responsePDFTemplate = await request.executeRequest();
  if (
    responsePDFTemplate &&
    responsePDFTemplate.code === 200 &&
    responsePDFTemplate.response &&
    typeof responsePDFTemplate.response === 'object'
  ) {
    await handleRequestSendDataNotificationEmail(
      dataNotificationEmail,
      reloadParentComponent,
      reloadDataTemplates
    );
  } else {
    if (
      responsePDFTemplate &&
      responsePDFTemplate.code === 422 &&
      responsePDFTemplate.response &&
      responsePDFTemplate.response.error &&
      typeof responsePDFTemplate.response.message === 'object'
    ) {
      const keysErrors = Object.keys(responsePDFTemplate.response.message);
      setErrors(responsePDFTemplate.response.message);
      NotificationManager.error(
        responsePDFTemplate.response.message[keysErrors[0]][0],
        'Revisa los errores marcados en rojo:',
        4500
      );
    } else {
      NotificationManager.error(
        responsePDFTemplate && responsePDFTemplate.response ?
          responsePDFTemplate.response.message : 'Ha ocurrido un error desconocido, vuelve a intentarlo...',
        '',
        4500
      );
    }
  }
};

export const handleRequestSendDataNotificationEmail = async (
  data, reloadParentComponent, reloadDataTemplates
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/expedient_notifications/send`,
    "post",
    null,
    { ...data }
  );

  const responseSendEmail = await request.executeRequest();
  if (
    responseSendEmail && responseSendEmail.code === 200
  ) {
    await reloadDataTemplates();
    await reloadParentComponent(
      'El archivo fue cargado correctamente y la notificación fue enviada a los correos seleccionados',
      '',
      4500
    );
  } else {
    NotificationManager.error(
      'Ha ocurrido un error inesperado, es probable que haya errores en la configuración del correo electrónico, por favor revísala.',
      '',
      5000
    );
  }
};

export const renderFieldsPromotionTemplate = (
  promotion,
  setPromotion,
  errors,
  setErrors,
  dataExpedient,
  reloadComponent,
  dataUploadEvidences
) => {
  if (!promotion['promoter']) setPromotion({ ...promotion, promoter: dataExpedient['actor'] || 'N/A' });

  return (
    <>
      <Form.Group>
        <Form.Label>* Nombre del promovente:</Form.Label>
        <Form.Control
          value={promotion["promoter"] ? promotion["promoter"] : ""}
          aria-label="Nombre del promovente"
          onChange={(e) => {
            setPromotion({ ...promotion, promoter: e.target.value });
            setErrors({ ...errors, promoter: [] });
          }}
          type="text"
          disabled={true}
        />
        {renderErrorsByInputName(errors, "promoter")}
      </Form.Group>

      <Form.Group>
        <Form.Label>* Número de copias del escrito:</Form.Label>
        <Form.Control
          value={promotion["amount_copies"] ? promotion["amount_copies"] : ""}
          aria-label="Número de copias del escrito"
          onChange={(e) => {
            setPromotion({ ...promotion, amount_copies: e.target.value });
            setErrors({ ...errors, amount_copies: [] });
          }}
          type="number"
          min={0}
          onInput={(e) => {
            if (e.target.value < 0) e.target.value = 0;
          }}
        />
        {renderErrorsByInputName(errors, "amount_copies")}
      </Form.Group>

      <Form.Group >
        <Form.Label>* Número de firmas:</Form.Label>
        <Form.Control
          value={promotion["amount_signatures"] ? promotion["amount_signatures"] : ""}
          aria-label="Número de firmas"
          onChange={(e) => {
            setPromotion({ ...promotion, amount_signatures: e.target.value });
            setErrors({ ...errors, amount_signatures: [] });
          }}
          type="number"
          min={0}
          onInput={(e) => {
            if (e.target.value < 0) e.target.value = 0;
          }}
        />
        {renderErrorsByInputName(errors, "amount_signatures")}
      </Form.Group>

      <Form.Group>
        <Form.Label>* ¿Quieres agregar anexos?</Form.Label>
        <Form.Check
          type="radio"
          label={"Si"}
          checked={promotion['hasProofs'] === "si" ? true : false}
          id={"si"}
          value="si"
          onChange={(e) => {
            setPromotion({ ...promotion, hasProofs: e.target.value });
          }}
        />
        <Form.Check
          type="radio"
          label={"No"}
          checked={promotion['hasProofs'] === "no" ? true : false}
          id={"no"}
          value="no"
          onChange={(e) => {
            setPromotion({ ...promotion, hasProofs: e.target.value });
          }}
        />
        {renderErrorsByInputName(errors, "hasProofs")}
      </Form.Group>

      {promotion['hasProofs'] === 'si' ?
        <Form.Group controlId="formBasicFile">
          <Form.Label>* Anexos</Form.Label>
          <TableUploadManyFiles
            acceptFiles={[".png", ".pdf", ".jpg", ".jpeg"]}
            getFiles={(files) => {
              if (files.length > 0) {
                setPromotion({
                  ...promotion,
                  evidence_file: files
                });
                setErrors({
                  ...errors,
                  evidence_file: []
                });
              }
              else {
                delete promotion['evidence_file'];
              }
            }}
            reloadDataComponent={reloadComponent}
            dataUploadEvidences={dataUploadEvidences}
            expedientId={dataExpedient.id}
            errorsHeredated={errors}
          />
        </Form.Group> : ''}
    </>
  );
};

export const bodyModalNotifications = (documents) => {
  const notifications = documents.map((document) => {
    return document.expedient_notifications;
  });

  return (
    <div>
      <table className="table table-collapsed table-bordered mT-10">
        <tbody>
          <tr className="table-documents-modal">
            <th className="text-center">Nombre</th>
            <th className="text-center">Correo</th>
            <th className="text-center">Correo leído</th>
            <th className="text-center">Correo enviado</th>
            <th className="text-center">Enlace de descarga</th>
          </tr>
          {
            notifications.map((notifArr, index) => {
              if (notifArr.length === 0) {
                return (
                  <tr key={index}>
                    <td
                      style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }}
                      className="text-center"
                      colSpan={4}
                    >
                      {'Sin notificación electrónica'}
                    </td>
                    <td
                      className='center-horizontally-vertically-parent'
                    >
                      <a
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        href={documents[index]['file_path'] + "?token=" + process.env.REACT_APP_TOKEN}
                        className="center-horizontally-vertically-child"
                      >
                        <Button
                          variant="primary"
                          style={{ fontSize: "0.85rem" }}
                          size="sm"
                        >
                          Archivo digital <i className="ti-download" title={"Descargar " + documents[index]['alias']} />
                        </Button>
                      </a>
                    </td>
                  </tr>
                )
              }
              return (
                notifArr.map((notif, i) => (
                  <tr key={i}>
                    <td
                      style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }}
                      className="text-center"
                    >
                      {notif['user_fullname'] || ''}
                    </td>
                    <td
                      style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }}
                      className="text-center"
                    >
                      {notif['user_email'] || ''}
                    </td>
                    <td
                      style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }}
                      className="text-center"
                    >
                      {notif['notified_at'] || ''}
                    </td>
                    <td
                      style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }}
                      className="text-center"
                    >
                      {notif['sent_at'] || ''}
                    </td>
                    <td
                      className='center-horizontally-vertically-parent'
                      rowSpan={notifArr.length}
                      style={i === 0 ? null : { display: 'none' }}
                    >
                      <a
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        href={documents[index]['file_path'] + "?token=" + process.env.REACT_APP_TOKEN}
                        className="center-horizontally-vertically-child"
                      >
                        <Button
                          variant="primary"
                          style={{ fontSize: "0.85rem" }}
                          size="sm"
                        >
                          Archivo digital <i className="ti-download" title={"Descargar " + documents[index]['alias']} />
                        </Button>
                      </a>
                    </td>
                  </tr>
                ))
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export const footerModalNotifications = (setCloseModal) => {

  return (
    <>
      <Button variant="secondary" onClick={() => setCloseModal()} >Cerrar</Button>
    </>
  );
};

export const typesOfTemplatesToHideCommentsList = [
  'email',
  'notification',
];

export const getIsCommentFieldShowingInTemplate = (templaType = '') => {
  return !typesOfTemplatesToHideCommentsList.includes(templaType);
}

export const typesOfFilesToHideCommentsList = [
  'notification',
];

export const getIsCommentFieldShowingInFile = (fileType = '') => {
  return !typesOfFilesToHideCommentsList.includes(fileType);
}

export const getIsFieldShowingInFile = (fileType = '') => {
  return !typesOfTemplatesToHideCommentsList.includes(fileType);
}

const years = range(1800, 2040);
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];
export const secondCustomHeaderDatePicker = (
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
) => {

  return (
    <div>
      <button
        onClick={(e) => {
          e.preventDefault();
          decreaseMonth();
        }}
        disabled={prevMonthButtonDisabled}
        style={{border:'2px solid #216ba5', borderRadius:'5px', backgroundColor:'#216ba5', fontSize:'10px'}}
      >
        <i className="fas fa-arrow-left" style={{color:'white', fontSize:'8px'}} title="Last year"/>
      </button>
      <select
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) =>
          changeMonth(months.indexOf(value))
        }
      >
        {months.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        onClick={(e) => {
          e.preventDefault();
          increaseMonth();
        }}
        disabled={nextMonthButtonDisabled}
        style={{border:'2px solid #216ba5', borderRadius:'5px', backgroundColor:'#216ba5', fontSize:'10px'}}
      >
        <i className="fas fa-arrow-right" style={{color:'white', fontSize:'8px'}} title="Next year"/>
      </button>
    </div>
  )
};

export const findCrimeRequest = async (pattern = "") => {
  try {
    const request = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/crimes/search/${pattern}`,
      "get",
      null,
      {}
    );
  
    const responseCrimeRequest = await request.executeRequest();
    if (responseCrimeRequest.code === 200) {
      return responseCrimeRequest.response.crimes;
    }
  }
  catch(err) {
    return [];
  }
}

export const findCrimeById = async (id = 0) => {
  try {
    const request = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/crimes/${id}`,
      "get",
      null,
      {}
    );
  
    const responseCrimeRequest = await request.executeRequest();
    if (responseCrimeRequest.code === 200) {
      return responseCrimeRequest.response.crime;
    }
  }
  catch(err) {
    return null;
  }
}

export const printCover = async (expedientId) => {
  try {
    const url = `${process.env.REACT_APP_URL_API_LARAVEL}/coverpage/print/${expedientId}`;
    const request = new RequestNewVersion(
      url,
      "get",
      null,
      {},
      {
        responseType: 'arraybuffer'
      }
    );

    const response = await request.executeRequest();
    if(response.completeResponse.status === 200){
      const blob = new Blob([response.completeResponse.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const pdfWindow = window.open(url, '_blank');
      return pdfWindow.print();
    }
  } catch (error) {
    return null;
  }
}