import { useRef } from "react";
import { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { Request } from "../../../classes/Request";
import { RequestNewVersion } from "../../../classes/RequestNewVersion";

const useUsersAndPermissionsListModal = ({ setModalShowing, documentExpedientId, expedientId }) => {

  const [isLoadingUsersPermissions, setIsLoadingUsersPermissions] = useState(false);
  const [usersPermissions, setUsersPermissions] = useState([]);
  const [usersPermissionsToSet, setUsersPermissionsToSet] = useState([]);
  const [usersPermissionsToSearch, setUsersPermissionsToSearch] = useState('');
  const [showConfirmModal,setShowConfirmModal]= useState(false);
  const electronicExpedientUsersPermissions = useRef([]);


  const onUsersPermissionsToSearchInputChange = ({ target: { value } }) => {
    if (!value) {
      getDocumentUsersPermissions();
    }
    setUsersPermissionsToSearch(value);
  }

  const onSearchUserPermision = async (event) => {
    event.preventDefault();
    if (!usersPermissionsToSearch) {
      return
    }
    try {
      setIsLoadingUsersPermissions(true);
      const searchUsersPermissionsRequest = new Request(
        `${process.env.REACT_APP_URL_API_LARAVEL}/docuemnts_expedient/search_users/${usersPermissionsToSearch}/${documentExpedientId}`,
        "get",
        null,
        {}
      );
      const serachUsersPermissionsResponse = await searchUsersPermissionsRequest.executeRequest(false);

      if (serachUsersPermissionsResponse && serachUsersPermissionsResponse.code && Number(serachUsersPermissionsResponse.code === 200)) {
        if (serachUsersPermissionsResponse.data.data.data.message === 'Usuario no encontrado') {
          setUsersPermissions([]);
          return
        }
        const _usersPermissions = getUpdatedPermissionsIfTheyHaveBeenChanged(serachUsersPermissionsResponse.data.data.data.users, usersPermissionsToSet);
        setUsersPermissions(_usersPermissions);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error("Ocurrió un error al intentar traer permisos");
    } finally {
      setIsLoadingUsersPermissions(false);
    }
  }

  const getElectronicExpedientUsersPermissionsFormatted = (_electronicExpedientUsersPermissions) => {
    const electronicExpedientUsersPermissionsFormatted = _electronicExpedientUsersPermissions.map((singleElectronicExpedientUserPermission) => {
      return {
        ...singleElectronicExpedientUserPermission,
        isPermissionEnabled: false,
        fullname: singleElectronicExpedientUserPermission.name,
      }
    })
    return electronicExpedientUsersPermissionsFormatted;
  }

  const getElectronicExpedientUsersPermissions = async () => {
    const requestGetElectronicExpedientUsersPermissions = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/get_user_permissions/${expedientId}`,
      "get",
      null,
      {}
    );

    try {
      setIsLoadingUsersPermissions(true);
      const { code, response: { permissions = [] } } = await requestGetElectronicExpedientUsersPermissions.executeRequest();
      if (Number(code) === 200) {
        electronicExpedientUsersPermissions.current = permissions
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoadingUsersPermissions(false);
    }
  }

  const getDocumentUsersPermissions = async () => {
    try {
      setIsLoadingUsersPermissions(true);
      const usersPermissionsRequest = new Request(
        `${process.env.REACT_APP_URL_API_LARAVEL}/docuemnts_expedient/get_user_permissions/${documentExpedientId}`,
        "get",
        null,
        {}
      );
      const usersPermissionsResponse = await usersPermissionsRequest.executeRequest(false);
      if (usersPermissionsResponse && usersPermissionsResponse.code && Number(usersPermissionsResponse.code === 200)) {
        const _usersPermissions = getUpdatedPermissionsIfTheyHaveBeenChanged(usersPermissionsResponse.data.data.data.users, usersPermissionsToSet);
        const electronicExpedientUsersPermissionsFormatted = getElectronicExpedientUsersPermissionsFormatted(electronicExpedientUsersPermissions.current);
        const usersPermissionsMergedWithElectronicExpedientUsersPermissions = getUpdatedPermissionsIfTheyHaveBeenChanged(electronicExpedientUsersPermissionsFormatted, _usersPermissions);
        setUsersPermissions(usersPermissionsMergedWithElectronicExpedientUsersPermissions);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error("Ocurrió un error al intentar traer permisos");
    } finally {
      setIsLoadingUsersPermissions(false);
    }
  }

  const getAllUsersPermissions = async () => {
    await getElectronicExpedientUsersPermissions();
    getDocumentUsersPermissions();
  }

  useEffect(() => {
    getAllUsersPermissions();
  }, [])

  const onPermissionToggled = ({ target: { checked, value } }) => {
    const userAlreadyExistedInUsersPermissionsToSet = usersPermissionsToSet.some((user) => String(user.id) === String(value));

    let _usersPermissionsToSet = usersPermissionsToSet;
    if (userAlreadyExistedInUsersPermissionsToSet) {
      const usersPermissionsToSetFiltered = usersPermissionsToSet.filter((user) => String(user.id) !== String(value));
      _usersPermissionsToSet = usersPermissionsToSetFiltered;
    }

    let _userPermissionToSet = usersPermissions.find((user) => String(user.id) === String(value));
    _userPermissionToSet = { ..._userPermissionToSet, isPermissionEnabled: checked }
    setUsersPermissionsToSet([..._usersPermissionsToSet, _userPermissionToSet]);
  }

  const onPostUsersPermissions = async () => {
    if(!usersPermissionsToSet.length){
      setModalShowing(false);
      return;
    }
    setIsLoadingUsersPermissions(true);
    let shouldModalBeClosed = false;

    const uersPermissionsToSetRequest = new Request(
      `${process.env.REACT_APP_URL_API_LARAVEL}/docuemnts_expedient/document_expedient_users_permissions/${documentExpedientId}`,
      "post",
      null,
      { users: usersPermissionsToSet }
    );

    try {
      const uersPermissionsToSetResponse = await uersPermissionsToSetRequest.executeRequest(false);
      if (uersPermissionsToSetResponse.code && Number(uersPermissionsToSetResponse.code) === 200) {
        shouldModalBeClosed = true;
      }
      else {
        throw new Error()
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error("Ocurrió un error al intentar guardar ");
    } finally {
      setIsLoadingUsersPermissions(false);
      if (shouldModalBeClosed) {
        NotificationManager.success("Cambios realizados con éxito");
        setModalShowing(false);
      }
    }
  }

  const getUpdatedPermissionsIfTheyHaveBeenChanged = (currentPermisssions, _usersPermissionsToSet) => {
    if (!currentPermisssions.length && _usersPermissionsToSet.length) {
      return _usersPermissionsToSet;
    }

    const updatedPermisssions = [...currentPermisssions];
    currentPermisssions.forEach((currentPermisssionElement, currentPermisssionIndex) => {
      _usersPermissionsToSet.forEach((usersPermissionToSetElement) => {
        if (String(currentPermisssionElement.id) === String(usersPermissionToSetElement.id)) {
          updatedPermisssions[currentPermisssionIndex] = usersPermissionToSetElement;
        }
      })
    });

    return updatedPermisssions;
  }

  return (
    {
      onPermissionToggled,
      isLoadingUsersPermissions,
      usersPermissions,
      onPostUsersPermissions,
      onUsersPermissionsToSearchInputChange,
      usersPermissionsToSearch,
      onSearchUserPermision,
      showConfirmModal,
      setShowConfirmModal
    }
  )
}

export default useUsersAndPermissionsListModal