import React, { useEffect, useState } from 'react'
import FormTitle from '../../FormTitle'
import { Form } from 'react-bootstrap'
import { useIndicatorsContext } from '../../../context/indicatorsContext/IndicatorsContext'
import { finalJudgmentDefaultFormValues } from '../../../constants/defaultValues'
import _ from 'lodash'
import { renderErrorsByInputName } from '../../../../../../my_expedients/expedients_list_logic'
import { PrimitivesEntity } from '../../../../../../../../shared/entities/primitives.entity'
import { CrimesMultiselect } from '../../../../../../../shared/crimes/CrimesMultiselect'
import { UserEntity } from '../../../../../../../../shared/entities/user.entity'
const FinalJudgment = () => {
  const { finalJudgment, interlocutoryJudgment } = useIndicatorsContext();
  const [userMatters, setUserMatters] = useState([]);
  
  const {
    finalJudgmentFormMethods: {
      onFormChange,
      formValues,
      onFormRadioChange,
      onFormCheckChange,
      onFormChangeWithKeyValue
    },
    errors,
    assertions,
  } = finalJudgment;

  const {
    isASenseResolutionpProceeded,
    isNotASenseResolutionpProceeded,
  } =assertions;

  useEffect(() => {
    const getMatters = async () => {
      const matters = await UserEntity.getMyMatter();
      setUserMatters(matters);
    }
    getMatters();
  }, []);
  return (
    <div>
      <FormTitle>
        Sentencia definitiva
      </FormTitle>
      <Form>
        <Form.Group controlId="measures_reparation_decreed">
          <Form.Label>
            Medidas de reparacion del daño decretadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_reparation_decreed'
            onChange={onFormChange}
            value={_.defaultTo(formValues.measures_reparation_decreed, finalJudgmentDefaultFormValues.measures_reparation_decreed)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName(errors, 'measures_reparation_decreed')}
        </Form.Group>

        <Form.Group controlId="measures_reparation_denied">
          <Form.Label>
            Medidas de reparacion del daño negadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_reparation_denied'
            onChange={onFormChange}
            value={_.defaultTo(formValues.measures_reparation_denied, finalJudgmentDefaultFormValues.measures_reparation_denied)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName(errors, 'measures_reparation_denied')}
        </Form.Group>

        <Form.Group controlId="measures_protection_decreed">
          <Form.Label>
            Medidas de proteccion decretadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_protection_decreed'
            onChange={onFormChange}
            value={_.defaultTo(formValues.measures_protection_decreed, finalJudgmentDefaultFormValues.measures_protection_decreed)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName(errors, 'measures_protection_decreed')}
        </Form.Group>

        <Form.Group controlId="measures_protection_denied">
          <Form.Label>
            Medidas de proteccion negadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_protection_denied'
            onChange={onFormChange}
            value={_.defaultTo(formValues.measures_protection_denied, finalJudgmentDefaultFormValues.measures_protection_denied)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName(errors, 'measures_protection_denied')}
        </Form.Group>
        {userMatters.includes('penal') &&
          <Form.Group controlId="principal_action">
            <CrimesMultiselect
              isDisabled={false}
              matter={'penal'}
              changeMainForm={onFormChangeWithKeyValue}
              defaultCrimes={_.defaultTo(formValues.crimes, finalJudgmentDefaultFormValues.crimes)}
              isError={false}
              isUpdate={true}
              handleChangeUpdate={onFormChangeWithKeyValue}
            />
          </Form.Group>
        }
        <Form.Group controlId="sense_resolution">
          <Form.Text className="text-muted">
            Sentido de la resolución
          </Form.Text>
          <Form.Check
            inline
            type="radio"
            label="Procedente"
            name="sense_resolution"
            id='sense_resolution_proceed'
            value={'proceed'}
            checked={isASenseResolutionpProceeded}
            onChange={onFormRadioChange}
          />
          <Form.Check
            inline
            type="radio"
            label="Improcedente"
            name="sense_resolution"
            id='sense_resolution_no_proceed'
            value={'no_proceed'}
            checked={isNotASenseResolutionpProceeded}
            onChange={onFormRadioChange}
          />
        </Form.Group>
        { isASenseResolutionpProceeded &&
          <Form.Group controlId="protocol_options">
            <Form.Text className="text-muted">
              Selecciona una opción
            </Form.Text>
            <Form.Check
              inline
              type='checkbox'
              label='Principal'
              name='principal'
              id='principal'
              checked={PrimitivesEntity.parseBooleanOrString(formValues.principal)}
              onChange={onFormCheckChange}
            />
            <Form.Check
              inline
              type='checkbox'
              label='Reconvención'
              name='counterclaim'
              id='counterclaim'
              checked={PrimitivesEntity.parseBooleanOrString(formValues.counterclaim)}
              onChange={onFormCheckChange}
            />
          </Form.Group>
        }
      </Form>
    </div>
  )
}

export default FinalJudgment