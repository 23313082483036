import React, { useState, useEffect, useContext } from "react";
import { RemotePagination } from "../../helper_components/remote_pagination/RemotePagination";
import { Grid } from "react-flexbox-grid";
import NavBar from "../../layout/shared/NavBar";
import ExpedientsSearcher from "../../helper_components/expedients_searcher/ExpedientSearcher";
import {
    renderErrorsByInputName,
    fieldsValidation,
    cleanOfInputsDeleted,
    disabledButtonsToSingleTransfer,
    getCatalogOptionsKindJudgment,
    getCatalogOptionsLegalWays,
    selectStylesDefault,
    selectStylesError,
    getTypeExpedients
} from "../my_expedients/expedients_list_logic";
import { 
  getDependencesToTransfer, 
  getExpedientsToTurnForeign, 
  getSearchOfExpedients,
  transferForeignExpedient,
  multipleReleaseExpedients, 
  bodyDespaused,
  footerDespaused,
  toDespauseExpedient, initColumnsTurnForeign } from "./turn_foreign_logic";
import { Button, Form } from "react-bootstrap";
import ModalComponent from "../../helper_components/ModalComponent";
import Select from "react-select";
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";
import Footer from "../../layout/shared/Footer";
import Cookies from "js-cookie";
import _ from "lodash";
import { procedureTypeOptions } from "../details_expedient/constants/procedureTypeOptions";
import { getLabelFromDictionary } from "../../../config/dictionary";

const TurnForeignList = props => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalProcedures, setTotalProcedures] = useState(5);
  const [searching,setSearching] = useState(false);
  const [cleaning,setCleaning] = useState(false);
  const [search,setSearch] = useState("");
  const [show,setShow] = useState(false);
  const [options,setOptions] = useState([]);
  const [errors,setErrors] = useState({});
  const [transfer,setTransfer] = useState({});
  const [toCreate,setToCreate] = useState(false);
  const [textNotData,setTextNotData] = useState("");
  const [nonSelectableArray,setNonSelectableArray] = useState([]);
  const [openModalMultipleTransfer,setOpenModalMultipleTransfer] = useState(false);
  const [multipleTransfer,setMultipleTransfer] = useState({});
  const columns = initColumnsTurnForeign( JSON.parse(localStorage.getItem('elementsAddedForeign')) && JSON.parse(localStorage.getItem('elementsAddedForeign')).length > 0 ? true : false );
  const loader = useContext(LoaderContext);
  const [kindJudgmentOptions,setKindJudgmentOptions] = useState([]);
  const [legalWaysOptions,setLegalWaysOptions] = useState([]);
  const [typeExpedients, setTypeExpedients] = useState([]);
  const { userId_03 } = Cookies.get();
  const [ showDespaused, setShowDespaused ] = useState(false);
  const [ bodyDespause, setBodyDespause ] = useState({
    sign: false,
    observation: ""
  });
  const [ expedientToDespause, setExpedientToDespause ] = useState({});

  const isAnExternalTransfer = _.get(transfer, 'external');
  const doesReceiverHaveId2 = _.get(transfer, 'dependence_id') === 2;
  const isASupremeCourtOfJusticeReceiver = isAnExternalTransfer && doesReceiverHaveId2;
  const isAnAppealTypeProcedure = _.get(transfer, 'procedure_type.value') === 1;

  useEffect(() => {
    const elementsAddedStorage = JSON.parse(localStorage.getItem('elementsAddedForeign')) ? JSON.parse(localStorage.getItem('elementsAddedForeign')) : [];
    (async function() {
        await getExpedientsToTurnForeign(
            setData,
            setTotalProcedures,
            page,
            sizePerPage,
            setFunctionsToOpenModal,
            setOptions,
            setTransfer,
            transfer,
            setTextNotData,
            setNonSelectableArray,
            openModalDespaused,
            props,
            loader,
        );
        renderInputOfComments(elementsAddedStorage);
        disabledButtonsToSingleTransfer(elementsAddedStorage, true);
    })();
  }, []);

  const renderInputOfComments = ( elements ) => {

    for ( let i = 0; i < elements.length; i++ ) {
      let divContainer = document.getElementById(elements[i]['id'] + 'div-comments') ? document.getElementById(elements[i]['id'] + 'div-comments') : null;
      if ( divContainer && divContainer.childNodes.length < 1 ) {
        var textarea = document.createElement('textarea');
        textarea.id = elements[i]['id'] + 'input-comments';
        textarea.rows = 4;
        textarea.cols = 20;
        textarea.value = elements[i]['comments']
        textarea.style.width = '100%';
        textarea.addEventListener(
          'input',
          (e) => commentsOnChange(e, elements[i]['id']),
          false
       );
  
        divContainer.appendChild(textarea);
      }
    }
  };

  const setTransferExpedient = async () => {
    const requiredFields = toCreate && transfer["with_defendant"] ? 
                          // ["expedient_number", "receiver_id", "receiver","actor_name", "actor_firstname", "defendant_name", "defendant_firstname", "kind_judgment", "via", "external", "dependence_id", "type_expedient"] : Se tiene que cambiar por la de abajo para integrar el tipo de expediente
                          ["expedient_number", "receiver_id", "receiver","actor_name", "actor_firstname", "defendant_name", "defendant_firstname", "kind_judgment", "via", "external", "dependence_id"] :
                          toCreate && !transfer["with_defendant"] ?
                          // ["expedient_number", "receiver_id", "receiver", "actor_name", "actor_firstname", "kind_judgment", "via", "external", "dependence_id", "type_expedient"] : Se tiene que cambiar por la de abajo para integrar el tipo de expediente
                          ["expedient_number", "receiver_id", "receiver", "actor_name", "actor_firstname", "kind_judgment", "via", "external", "dependence_id"] :
                          // ["expedient_number","receiver_id", "receiver", "external", "dependence_id", "type_expedient"]; Se tiene que cambiar por la de abajo para integrar el tipo de expediente
                          ["expedient_number","receiver_id", "receiver", "external", "dependence_id"];

    if (isASupremeCourtOfJusticeReceiver) {
      requiredFields.push('procedure_type');
    }

    if (isAnAppealTypeProcedure) {
      requiredFields.push('appellant_full_name');
    }

    const responseValidation = fieldsValidation( requiredFields, transfer );

    if ( typeof responseValidation === "object" ) setErrors(responseValidation)
    else {

      const transferToSend = !toCreate ? _.omit(transfer, ['via', 'kind_judgment']) : transfer;
      await transferForeignExpedient(
        transferToSend,
        props,
        setErrors,
        toCreate
      );
    }
  };

  const setFunctionsToOpenModal = async ( create ) => {

    if ( !create ){
      setToCreate(false);
      setErrors({});
      setShow(true);
    }
    else {
      setToCreate(true);
      await getDependencesToTransfer(
        setShow,
        setOptions,
        null,
        setTransfer,
        transfer
      );
      setErrors({});
      setShow(true);
    }
  };

  const setCloseModal = () => {

    setTransfer({});
    setShow(false);
  };


  const handleTableChange = async (type, { page, sizePerPage }) => {
    const elementsAddedStorage = JSON.parse(localStorage.getItem('elementsAddedForeign')) ? JSON.parse(localStorage.getItem('elementsAddedForeign')) : [];
    setPage(page);
    setSizePerPage(sizePerPage);

    await getExpedientsToTurnForeign(
        setData,
        setTotalProcedures,
        page,
        sizePerPage,
        setFunctionsToOpenModal,
        setOptions,
        setTransfer,
        transfer,
        setTextNotData,
        setNonSelectableArray,
        openModalDespaused,
        undefined,
        loader,
    );

    disabledButtonsToSingleTransfer(elementsAddedStorage, true);
    renderInputOfComments(elementsAddedStorage);
  };

  const searchExpedients = async () => {
    const elementsAddedStorage = JSON.parse(localStorage.getItem('elementsAddedForeign')) ? JSON.parse(localStorage.getItem('elementsAddedForeign')) : [];

    if ( search.length > 0 && /^$|.*\S+.*/.test(search) ) {

        setSearching(true);
        loader.show(true);
        await getSearchOfExpedients(
          search.replace(/^\s+|\s+$/gm,''),
          10,
          1,
          setData,
          setTotalProcedures,
          setFunctionsToOpenModal,
          setOptions,
          setTransfer,
          transfer,
          setTextNotData,
          null,
          openModalDespaused
        );

        disabledButtonsToSingleTransfer(elementsAddedStorage, true);
        renderInputOfComments(elementsAddedStorage);
        setSearching(false);
        loader.show(false);
    }

  };

  const cleanSearch = async () => {
    const elementsAddedStorage = JSON.parse(localStorage.getItem('elementsAddedForeign')) ? JSON.parse(localStorage.getItem('elementsAddedForeign')) : [];

    setSearch("");
    setCleaning(true);
    await getExpedientsToTurnForeign(
        setData,
        setTotalProcedures,
        1,
        10,
        setFunctionsToOpenModal,
        setOptions,
        setTransfer,
        transfer,
        setTextNotData,
        setNonSelectableArray,
        openModalDespaused,
        undefined,
        loader,
    );

    disabledButtonsToSingleTransfer(elementsAddedStorage, true);
    renderInputOfComments(elementsAddedStorage);
    setCleaning(false);
  };

  const bodyModalMultipleTransfer = (
    <>
      <Form>
        { renderErrorsByInputName(
          errors["expedients"] ? {"expedients":[`Aún no has seleccionado algún ${getLabelFromDictionary('global', 'singleLowerMainName')} para transferir`]} : errors, 
          "expedients"
          ) 
        }
        { renderErrorsByInputName(errors, "general_errors") }

        <Form.Group>
          <Form.Label>* Dependencia destinataria:</Form.Label>
          <Select
            options={options}
            onChange={selected => {
              setMultipleTransfer({ 
                ...multipleTransfer, 
                dependence_id: selected["value"],
                receiver_id: userId_03,
                receiver: selected,
                external: true
              })

              const newErros = _.omit(errors, ['receiver_id', 'receiver', 'dependence_id']);
              setErrors(newErros);
            }}
            placeholder="Selecciona dependencia destinataria"
            styles={selectStylesDefault}
          />
          { renderErrorsByInputName(errors, "receiver_id") }
        </Form.Group>

      </Form>
    </>
  );

  const bodyModal = (
    <>
      <Form>

        <Form.Group >
          { renderErrorsByInputName(errors, "generalErrors") }
          <Form.Label>* Número de {getLabelFromDictionary('global', 'lowerMainName')}:</Form.Label>
          <Form.Control 
            disabled={  toCreate ? false : true } 
            value={ transfer["expedient_number"] ? transfer["expedient_number"] : "" } 
            aria-label="numero de expediente"
            onChange={ (e) => setTransfer( { ...transfer,  expedient_number:e.target.value} ) }
            placeholder={`Agrega el número de ${getLabelFromDictionary('global', 'lowerMainName')}`}
            onInput={(e) => e.target.value = e.target.value.replace(/\b0+/g, '')}
          />
          <Form.Text className={errors["expedient_number"] ? "d-n" : ""}>
            Ejemplo: 25/2019 (recuerda utilizar diagonal y capturar los cuatro dígitos del año)
          </Form.Text>
          { renderErrorsByInputName(errors, "expedient_number") }
        </Form.Group>

        {/* descomentar este bloque para integrar tipo de expediente */}
        {/* <Form.Group>
          <Form.Label>* Tipo de {getLabelFromDictionary('global', 'singleLowerMainName')}</Form.Label>
          <Select
            onFocus={() => getTypeExpedients(setTypeExpedients)}
            options={typeExpedients}
            onChange={(selected)=> setTransfer({ ...transfer, type_expedient: JSON.stringify(selected) })}
            placeholder={"Selecciona el tipo de " + getLabelFromDictionary('global', 'singleLowerMainName')}
            styles={Object.keys(errors).includes("type_expedient") ? selectStylesError : selectStylesDefault}
          />

          <Form.Text className={errors["type_expedient"] ? "d-n" : ""}>
            Selecciona el tipo de {getLabelFromDictionary('global', 'singleLowerMainName')}
          </Form.Text>
          {renderErrorsByInputName(errors, "type_expedient")}
        </Form.Group> */}
        {/* Fin de bloque */}

        <Form.Group>
          <Form.Label>* Dependencia destinataria:</Form.Label>
          <Select
            options={options}
            onChange={ selected => {
              setTransfer({ 
                ...transfer, 
                dependence_id: selected["value"], 
                receiver_id: userId_03, 
                receiver: selected,
                external: true 
              })

              const newErros = _.omit(errors, ['receiver_id', 'receiver', 'dependence_id']);
              setErrors(newErros);
            }}
            placeholder="Selecciona dependencia destinataria"
            styles={selectStylesDefault}
          />
          <Form.Text className={errors["receiver_id"] ? "d-n" : ""}>
            Selecciona el nombre de la dependencia a donde deseas turnar el {getLabelFromDictionary('global', 'lowerMainName')}
          </Form.Text>
          { renderErrorsByInputName(errors, "receiver_id") }
        </Form.Group>

        {
          isASupremeCourtOfJusticeReceiver
          &&
          <>
            <Form.Group>
              <Form.Label>
                <b style={errors.procedure_type ? { color: 'red' } : null}>
                  * Elige el tipo de trámite
                </b>
              </Form.Label>
              <Select
                options={procedureTypeOptions}
                onChange={(selected) => {
                  delete errors.procedure_type;
                  setTransfer({
                    ...transfer,
                    procedure_type: selected,
                  })
                }}
                placeholder="Da clic y elige algún tipo de trámite"
                styles={errors.procedure_type ? selectStylesError : selectStylesDefault}
                value={_.get(transfer, 'procedure_type', null)}
              />
              {renderErrorsByInputName(errors, errors.procedure_type)}
            </Form.Group>

            {
              isAnAppealTypeProcedure &&
              <Form.Group>
                <Form.Label>
                  <b style={errors.appellant_full_name ? { color: 'red' } : null}>
                    * Nombre completo del Apelante:
                  </b>
                </Form.Label>
                <Form.Control
                  isInvalid={Boolean(errors.appellant_full_name)}
                  placeholder="Ingresa el nombre completo del apelante"
                  value={_.get(transfer, 'appellant_full_name', '')}
                  name={'appellant_full_name'}
                  onChange={({ target }) => {
                    delete errors.appellant_full_name;
                    setTransfer({
                      ...transfer,
                      appellant_full_name: target.value,
                    })
                  }}
                />
                {renderErrorsByInputName(errors, errors.appellant_full_name)}
              </Form.Group>
            }
          </>
        }

        <Form.Group >
          <Form.Label>* Nombre(s) del actor:</Form.Label>
          <Form.Control 
            disabled={  toCreate ? false : true } 
            value={ transfer["actor_name"] ? transfer["actor_name"] : "" } 
            aria-label="actor"
            onChange={ (e) => setTransfer( { ...transfer,  actor_name:e.target.value} ) }
            placeholder="Agrega el nombre(s) del actor (promovente)"
          />
          { renderErrorsByInputName(errors, "actor_name") }
        </Form.Group>

        <Form.Group >
          <Form.Label>* Primer apellido del actor:</Form.Label>
          <Form.Control 
            disabled={  toCreate ? false : true } 
            value={ transfer["actor_firstname"] ? transfer["actor_firstname"] : "" } 
            aria-label="actor_firstname"
            onChange={ (e) => setTransfer( { ...transfer,  actor_firstname:e.target.value} ) }
            placeholder="Agrega el primer apellido del actor (promovente)"
          />
          { renderErrorsByInputName(errors, "actor_firstname") }
        </Form.Group>

        <Form.Group >
          <Form.Label>Segundo apellido del actor:</Form.Label>
          <Form.Control 
            disabled={  toCreate ? false : true } 
            value={ transfer["actor_secondname"] ? transfer["actor_secondname"] : "" } 
            aria-label="actor_secondname"
            onChange={ (e) => setTransfer( { ...transfer,  actor_secondname:e.target.value} ) }
            placeholder="Agrega el segundo apellido del actor (promovente)"
          />
          { renderErrorsByInputName(errors, "actor_secondname") }
        </Form.Group>

        <Form.Group >
          <Form.Label>Notificaciones electrónicas:</Form.Label>
          <Form.Control 
            disabled={  toCreate ? false : true } 
            value={ transfer["actor_email"] ? transfer["actor_email"] : "" } 
            aria-label="actor_email"
            onChange={ (e) => setTransfer( { ...transfer,  actor_email:e.target.value} ) }
            placeholder="Agrega el correo electrónico del actor (promovente)"
          />
          <Form.Text className={errors["actor_email"] ? "d-n" : ""}>
            Ejemplo: ana123@gmail.com
          </Form.Text>
          { renderErrorsByInputName(errors, "actor_email") }
        </Form.Group>

        <Form.Group>
          <Form.Label>Nombre del abogado patrono:</Form.Label>
          <Form.Control 
            value={ transfer["lawyer_actor_name"] ? transfer["lawyer_actor_name"] : "" } 
            aria-label="lawyer_actor_name"
            className={Object.keys(errors).includes("lawyer_actor_name") ? "text-danger is-invalid" : ""}
            placeholder="Escribe el nombre(s) del abogado patrono"
            onChange={ (e) => setTransfer( { ...transfer,  lawyer_actor_name:e.target.value} ) }
            disabled={  toCreate ? false : true }
          />
          <Form.Text className={errors["lawyer_actor_name"] ? "d-n" : ""}>
            En caso de existir la figura de abogado patrono capturar aquí el nombre(s)
          </Form.Text>
          { renderErrorsByInputName(errors, "lawyer_actor_name") }
        </Form.Group>

        <Form.Group>
          <Form.Label>Primer apellido del abogado patrono:</Form.Label>
          <Form.Control 
            value={ transfer["lawyer_actor_firstname"] ? transfer["lawyer_actor_firstname"] : "" } 
            aria-label="lawyer_actor_firstname"
            className={Object.keys(errors).includes("lawyer_actor_firstname") ? "text-danger is-invalid" : ""}
            placeholder="Escribe el primer apellido del abogado patrono"
            disabled={  toCreate ? false : true }
            onChange={ (e) => setTransfer( { ...transfer,  lawyer_actor_firstname:e.target.value} ) }
          />
          { renderErrorsByInputName(errors, "lawyer_actor_firstname") }
        </Form.Group>

        <Form.Group>
          <Form.Label>Segundo apellido del abogado patrono:</Form.Label>
          <Form.Control 
            value={ transfer["lawyer_actor_secondname"] ? transfer["lawyer_actor_secondname"] : "" } 
            aria-label="lawyer_actor_secondname"
            className={Object.keys(errors).includes("lawyer_actor_secondname") ? "text-danger is-invalid" : ""}
            placeholder="Escribe el nombre del abogado patrono"
            disabled={  toCreate ? false : true }
            onChange={ (e) => setTransfer( { ...transfer,  lawyer_actor_secondname:e.target.value} ) }
          />
          { renderErrorsByInputName(errors, "lawyer_actor_secondname") }
        </Form.Group>

        {
          toCreate ?
            <Form.Group>
              <Form.Label>Para este caso, ¿Es necesario agregar demandado?</Form.Label>
              <Form.Check
                type="checkbox"
                checked={transfer["with_defendant"] === "si"}
                value={"si"}
                onChange={ (e) => {
                  setTransfer({
                    ...transfer, 
                    with_defendant: transfer["with_defendant"] === "si" ? null : e.target.value,
                    defendant: "" 
                  });
                }}
                label={"Si"}
                id="si"
                style={{marginLeft:"15px"}}
              />
              <small className="text-secondary"><strong>NOTA: Sólo marcar la casilla cuando hay un demandado</strong></small>
            </Form.Group>
          : ""
        }
        {
          transfer['defendant_name'] || transfer['with_defendant'] === 'si' ? 
            <>
              <Form.Group>
                <Form.Label>* Nombre(s) del demandado:</Form.Label>
                <Form.Control 
                  disabled={  toCreate ? false : true } 
                  value={ transfer["defendant_name"] ? transfer["defendant_name"] : "" } 
                  aria-label="demandado"
                  onChange={ (e) => setTransfer( { ...transfer,  defendant_name:e.target.value} ) }
                  placeholder="Agrega el nombre(s) del demandado"
                />
                { renderErrorsByInputName(errors, "defendant_name") }
              </Form.Group>

              <Form.Group>
                <Form.Label>* Primer apellido del demandado:</Form.Label>
                <Form.Control 
                  disabled={  toCreate ? false : true } 
                  value={ transfer["defendant_firstname"] ? transfer["defendant_firstname"] : "" } 
                  aria-label="demandado_first"
                  onChange={ (e) => setTransfer( { ...transfer,  defendant_firstname:e.target.value} ) }
                  placeholder="Agrega el primer apellido del demandado"
                />
                { renderErrorsByInputName(errors, "defendant_firstname") }
              </Form.Group>

              <Form.Group>
                <Form.Label>Segundo apellido del demandado:</Form.Label>
                <Form.Control 
                  disabled={  toCreate ? false : true } 
                  value={ transfer["defendant_secondname"] ? transfer["defendant_secondname"] : "" } 
                  aria-label="demandado_second"
                  onChange={ (e) => setTransfer( { ...transfer,  defendant_secondname:e.target.value} ) }
                  placeholder="Agrega el segundo apellido del demandado"
                />
                { renderErrorsByInputName(errors, "defendant_secondname") }
              </Form.Group>

              <Form.Group >
                <Form.Label>Notificaciones electrónicas:</Form.Label>
                <Form.Control 
                  disabled={  toCreate ? false : true } 
                  value={ transfer["defendant_email"] ? transfer["defendant_email"] : "" } 
                  aria-label="defendant_email"
                  onChange={ (e) => setTransfer( { ...transfer,  defendant_email:e.target.value} ) }
                  placeholder="Agrega el correo electrónico del demandado"
                />
                <Form.Text className={errors["defendant_email"] ? "d-n" : ""}>
                  Ejemplo: ana123@gmail.com
                </Form.Text>
                { renderErrorsByInputName(errors, "defendant_email") }
              </Form.Group>

              <Form.Group>
                <Form.Label>Nombre(s) del abogado patrono:</Form.Label>
                <Form.Control 
                  value={ transfer["lawyer_defendant_name"] ? transfer["lawyer_defendant_name"] : "" } 
                  aria-label="lawyer_defendant_name"
                  onChange={ (e) => setTransfer( { ...transfer,  lawyer_defendant_name:e.target.value} ) }
                  className={Object.keys(errors).includes("lawyer_defendant_name") ? "text-danger is-invalid" : ""}
                  placeholder="Agrega el nombre(s) del demandado"
                  disabled={  toCreate ? false : true }
                />
                <small className="text-secondary">En caso de existir la figura de abogado patrono capturar aquí su nombre(s)</small>
                { renderErrorsByInputName(errors, "lawyer_defendant_name") }
              </Form.Group>

              <Form.Group>
                <Form.Label>Primer apellido del abogado patrono:</Form.Label>
                <Form.Control 
                  value={ transfer["lawyer_defendant_firstname"] ? transfer["lawyer_defendant_firstname"] : "" } 
                  aria-label="lawyer_defendant_firstname"
                  onChange={ (e) => setTransfer( { ...transfer,  lawyer_defendant_firstname:e.target.value} ) }
                  className={Object.keys(errors).includes("lawyer_defendant_firstname") ? "text-danger is-invalid" : ""}
                  placeholder="Agrega el primer apellido del demandado"
                  disabled={  toCreate ? false : true }
                />
                <small className="text-secondary">En caso de existir la figura de abogado patrono capturar aquí su primer apellido</small>
                { renderErrorsByInputName(errors, "lawyer_defendant_firstname") }
              </Form.Group>

              <Form.Group>
                <Form.Label>Segundo apellido del abogado patrono:</Form.Label>
                <Form.Control 
                  value={ transfer["lawyer_defendant_secondname"] ? transfer["lawyer_defendant_secondname"] : "" } 
                  aria-label="lawyer_defendant_secondname"
                  onChange={ (e) => setTransfer( { ...transfer,  lawyer_defendant_secondname:e.target.value} ) }
                  className={Object.keys(errors).includes("lawyer_defendant_secondname") ? "text-danger is-invalid" : ""}
                  placeholder="Agrega el segundo apellido del demandado"
                  disabled={  toCreate ? false : true }               
                />
                <small className="text-secondary">En caso de existir la figura de abogado patrono capturar aquí su segundo apellido</small>
                { renderErrorsByInputName(errors, "lawyer_defendant_secondname") }
              </Form.Group>
            </>
          : ""
        }

        <Form.Group>
          <Form.Label>* Tipo de Juicio:</Form.Label>
          <Select
            isDisabled={  toCreate ? false : true } 
            onFocus={ () => getCatalogOptionsKindJudgment(
              setKindJudgmentOptions
            )}
            options={kindJudgmentOptions}
            onChange={selected => setTransfer({ ...transfer, kind_judgment: selected["value"] })}
            placeholder="Selecciona el tipo de Juicio"
            defaultValue={ !toCreate ? transfer["kind_judgment"] : "" }
            styles={selectStylesDefault}
          />
          { renderErrorsByInputName(errors, "kind_judgment") }
        </Form.Group>

        <Form.Group>
          <Form.Label>* Vía:</Form.Label>
          <Select
            isDisabled={  toCreate ? false : true } 
            onFocus={ (e) => getCatalogOptionsLegalWays(
              setLegalWaysOptions
            )}
            options={legalWaysOptions}
            onChange={selected => setTransfer({ ...transfer, via: selected["value"] })}
            placeholder="Selecciona la vía"
            defaultValue={ !toCreate ? transfer["via"] : "" }
            styles={selectStylesDefault}
          />
          { renderErrorsByInputName(errors, "via") }
        </Form.Group>

        <Form.Group>
          <Form.Label>Observaciones:</Form.Label>
          <Form.Control 
            onChange={ (e) => setTransfer({ ...transfer, observation:e.target.value}) } 
            as="textarea"
            rows="5"
            aria-label="Observaciones"
            placeholder="Aquí puedes agregar algunas observaciones si lo deseas" 
          />
          <Form.Text className={errors["observation"] ? "d-n" : ""}>
            En caso de tener observaciones, captúralas aquí de manera clara y breve
          </Form.Text>
        </Form.Group>

      </Form>
    </>
  );

  const footerModalMultipleTransfer = (
    <>
      <Button
          variant="secondary"
          onClick={ () => setCloseModalMultipleTransfer() }
      >
          Cerrar
      </Button>
      <Button 
          variant="primary"
          type="submit"
          onClick={ () => sendMultipleTransfer() }
      >
          Enviar
      </Button>
    </>
  );

  const footerModal = (
    <>
      <Button
          variant="secondary"
          onClick={ () => setCloseModal() }
      >
          Cerrar
      </Button>
      <Button 
          variant="primary"
          type="submit"
          onClick={ () => setTransferExpedient() }
      >
          { toCreate ? "Enviar" : "Continuar"}
      </Button>
    </>
  );

  const onSelectRow = (row, isSelect, rowIndex, e) => {
    let elementsAddedStorage = JSON.parse(localStorage.getItem('elementsAddedForeign')) ? JSON.parse(localStorage.getItem('elementsAddedForeign')) : [];

    if ( isSelect ) {
      cleanOfInputsDeleted(elementsAddedStorage, row['id'] + 'input-comments' );
      const elementToAdd = {
        id:row['id'],
        expedient_number:row['expedient_number'],
        actor_name: row['actor_name'],
        actor_firstname: row['actor_firstname'],
        actor_secondname: row['actor_secondname'],
        defendant_name: row['defendant_name'],
        defendant_firstname: row['defendant_firstname'],
        defendant_secondname: row['defendant_secondname'],
        comments:"",
        kind_judgment: row['kind_judgment'],
        via: row['via']
      };

      localStorage.setItem('elementsAddedForeign', JSON.stringify(elementsAddedStorage.concat([elementToAdd])))

      let divContainer = document.getElementById(row['id'] + 'div-comments');
      if ( divContainer.childNodes.length < 1 ) {
        var textarea = document.createElement('textarea');
        textarea.id = row['id'] + 'input-comments';
        textarea.rows = 4;
        textarea.cols = 20;
        textarea.style.width = '100%';
        textarea.addEventListener(
          'input',
          (e) => commentsOnChange(e, row['id']),
          false
       );
  
        divContainer.appendChild(textarea);
      }

      disabledButtonsToSingleTransfer(elementsAddedStorage.concat(elementToAdd), true);
    }
    else {
      const arrayLoop = elementsAddedStorage.filter( element => element.id !== row['id'] );
      let divContainer = document.getElementById( row['id'] + 'div-comments' );
      divContainer.removeChild(divContainer.childNodes[0]);
      disabledButtonsToSingleTransfer([row], false);

      localStorage.setItem('elementsAddedForeign', JSON.stringify( arrayLoop ));
    }
  };

  const commentsOnChange = (e, expedientId) => {

    let elements = JSON.parse(localStorage.getItem('elementsAddedForeign'));
    if ( elements ){
      const index = elements.map( (element,i) => element.id).indexOf(expedientId);
      elements[index]['comments'] = e.target.value;
  
      localStorage.setItem('elementsAddedForeign', JSON.stringify(elements));
    }
  };


  const onSelectAllRows = (isSelect, rows, e) => {
    let elementsAddedStorage = JSON.parse(localStorage.getItem('elementsAddedForeign')) ? JSON.parse(localStorage.getItem('elementsAddedForeign')) : [];

    if ( isSelect ) {
      const rowsFiltered = rows.map( ({id, expedient_number, actor, defendant, kind_judgment, via}) => {

        let divContainer = document.getElementById(id + 'div-comments');
        if (  divContainer.childNodes.length < 1 ) {
          var textarea = document.createElement('textarea');
          textarea.id = id  + 'input-comments';
          textarea.rows = 4;
          textarea.cols = 20;
          textarea.style.width = '100%';
          textarea.addEventListener(
            'input',
            (e) => commentsOnChange(e, id),
            false
         );
    
          divContainer.appendChild(textarea);
        }

        const object = {
          id: id,
          expedient_number: expedient_number,
          comments:"",
          actor: actor,
          defendant: defendant,
          kind_judgment: kind_judgment,
          via: via
        }
  
        return object;
      });

      disabledButtonsToSingleTransfer(elementsAddedStorage.concat(rowsFiltered), true);
      localStorage.setItem('elementsAddedForeign', JSON.stringify(elementsAddedStorage.concat(rowsFiltered)));
    }
    else {
      const elementsFiltereds = elementsAddedStorage.filter(ar => !rows.find(rm => (rm.id === ar.id && ar.expedient_number === rm.expedient_number) ));
      for ( let i = 0; i < rows.length; i++ ) {
        let divContainer = document.getElementById( rows[i]['id'] + 'div-comments' );
        if ( divContainer ) {
          divContainer.removeChild(divContainer.childNodes[0]);
        }
      };

      disabledButtonsToSingleTransfer(rows, false);
      localStorage.setItem('elementsAddedForeign', JSON.stringify(elementsFiltereds));
    }
  };

  const sendExpedientsAdded = async () => {
    setErrors({});
    loader.show(true);
    await getDependencesToTransfer(
      () => setOpenModalMultipleTransfer(true),
      setOptions,
      null,
      null,
      null
      );
    loader.show(false);
  };

  const sendMultipleTransfer = async () => {
    const expedientsAdded = JSON.parse(localStorage.getItem('elementsAddedForeign')) ? JSON.parse(localStorage.getItem('elementsAddedForeign')) : [];
    const multipleTransferToCheck = {
      receiver_id:multipleTransfer['receiver_id'],
      receiver: multipleTransfer['receiver'],
      external: multipleTransfer['external'],
      dependence_id: multipleTransfer['dependence_id'],
      expedients: expedientsAdded.length > 0 ? expedientsAdded : null
    };

    const requiredFields = ["receiver_id", "receiver", "expedients", "external", "dependence_id"];
    const responseValidation = fieldsValidation( requiredFields, multipleTransferToCheck );

    if ( typeof responseValidation === "object" ) setErrors(responseValidation)
    else {
      const expedientsToSend = expedientsAdded.map( (expedient,index) => {
        let expedientSend = {};

        expedientSend['receiver_id'] = multipleTransferToCheck['receiver_id'];
        expedientSend['receiver'] = multipleTransferToCheck['receiver'];
        expedientSend['force'] = false;
        expedientSend['observation'] = expedient['comments'];
        expedientSend['expedient_number'] = expedient['expedient_number'];
        expedientSend['id'] = expedient['id'];
        expedientSend['actor'] = expedient['actor'];
        expedientSend['defendant'] = expedient['defendant'];
        expedientSend['dependence_id'] = multipleTransferToCheck['dependence_id'];
        expedientSend['external'] = multipleTransferToCheck['external'];
        
        return expedientSend;
      });

      await multipleReleaseExpedients(
        expedientsToSend,
        setCloseModalMultipleTransfer,
        setData,
        setTotalProcedures,
        page,
        sizePerPage,
        setFunctionsToOpenModal,
        setOptions,
        setTransfer,
        transfer,
        setTextNotData,
        setNonSelectableArray,
        openModalDespaused,
        loader,
      );
    }
  };

  const setCloseModalMultipleTransfer = () => {
    setMultipleTransfer({});
    setOpenModalMultipleTransfer(false);
  };

  const getElementsSelected = () => {
    let elementsAddedStorage = JSON.parse(localStorage.getItem('elementsAddedForeign')) ? JSON.parse(localStorage.getItem('elementsAddedForeign')) : [];
    const expedientsReduce = elementsAddedStorage.reduce( (array,element) => {
      array.push(element['id']);
      return array;
    },[]);

    return expedientsReduce;
  };

  const setCloseModalDespaused = () => {
    setBodyDespause({
      sign: false,
      observation: ""
    });
    setExpedientToDespause({});
    setShowDespaused(false);
  };

  const openModalDespaused = (expedient) => {
    setErrors({});
    setExpedientToDespause(expedient);
    setShowDespaused(true);
  };

  const sendActionToDespause = async () => {
    if ( bodyDespause['sign'] ) {
      await toDespauseExpedient(
        expedientToDespause, 
        bodyDespause,
        setData,
        setTotalProcedures,
        page,
        sizePerPage,
        setFunctionsToOpenModal,
        setOptions,
        setTransfer,
        transfer,
        setTextNotData,
        setNonSelectableArray,
        openModalDespaused,
        setCloseModalDespaused,
        loader,
      );
    }
    else setErrors({
      ...errors, 
      sign: [
        "Debes seleccionar la casilla y dar clic en el botón 'Aceptar' para confirmar la acción"
      ]
    });
  };

  return (
    <>
        <NavBar {...props} />
        
        <h3 className="mT-20 w-100 text-center">Turnado externo de {getLabelFromDictionary('global', 'lowerMainName')}</h3>
        
        <div style={{width:"85%",margin:"0px auto",display:"block"}}>

            <Button 
              id="newTransfer"
              onClick={ () => setFunctionsToOpenModal(true) }
            >
                <i className="fas fa-plus" />
                  {'  '} Nuevo turno externo
            </Button>

            <ExpedientsSearcher
                placeholderSearch={`Escribe el número de ${getLabelFromDictionary('global', 'lowerMainName')}`}
                placeholderSearchButton="Buscar"
                placeholderCleanButton="Limpiar"
                helperContextual={`Escribe el número de ${getLabelFromDictionary('global', 'lowerMainName')} y haz clic en el botón buscar.`}
                executeSearch={ searchExpedients }
                cleanSearch={ cleanSearch }
                isSearching={searching}
                isCleaning={cleaning}
                setSearch={setSearch}
                search={search}
                classNameButtonsSearch={"buttonsSearchExpedientsList"}
                hasRulesToSearch={true}
                rulesToSearch={/^[a-zA-Z0-9/]*$/}
            />

          <Button 
            onClick={ () => sendExpedientsAdded() }
            disabled={ JSON.parse(localStorage.getItem('elementsAddedForeign')) && JSON.parse(localStorage.getItem('elementsAddedForeign')).length > 0 ? false : true }
          >
            <i className="fas fa-send" />
            {'  '} Turnar {getLabelFromDictionary('global', 'lowerMainName')} seleccionados a externos
          </Button>   
        </div>


        <Grid fluid={true} className={ data.length > 0 ? "no-padding" : "d-n" } style={{minHeight: '76vh', width:"85%", margin:"0px auto"}}>
            <section className="procedures-table-container mT-30">

              <RemotePagination
                data={data}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalProcedures}
                onTableChange={handleTableChange}
                columns={columns}
                selectRow={
                  { 
                    mode:'checkbox', 
                    selectionHeaderRenderer: ({ mode, checked}) =>  ( 
                      <> 
                        <input id='all' type={ mode } checked={checked} onChange={()=>{}} /> 
                        {'  '}<label>Todo</label> 
                      </> 
                    ),
                    headerColumnStyle: { textAlign: 'center', lineHeight:'.5', minWidth:'70px', maxWidth:'70px' },
                    onSelect: (row, isSelect, rowIndex, e) => onSelectRow(row, isSelect, rowIndex, e), 
                    onSelectAll: (isSelect, rows, e) => onSelectAllRows(isSelect, rows, e),
                    selectColumnStyle: { textAlign:'center' },
                    nonSelectable: nonSelectableArray,
                    nonSelectableClasses: 'prueba-non-selectable',
                    selected: getElementsSelected()
                  }
                }
              />

            </section>
        </Grid>

        <h4 className={ data.length === 0 ? "text-center clearFloat padding-footer-not-data mT-20" : "d-n" }>{textNotData}</h4>
        
        <Footer/>

        <ModalComponent
          show={show}
          header={"Turnado externo"}
          body={bodyModal}
          footer={footerModal}
          canClose={true}
          onHide={setCloseModal}
        />

        <ModalComponent
          show={openModalMultipleTransfer}
          header={"Turnado múltiple"}
          body={bodyModalMultipleTransfer}
          footer={footerModalMultipleTransfer}
          canClose={true}
          onHide={setCloseModalMultipleTransfer}
        />

        <ModalComponent
          show={showDespaused}
          header={`Despausar ${getLabelFromDictionary('global', 'lowerMainName')}`}
          body={bodyDespaused(setBodyDespause, bodyDespause, renderErrorsByInputName(errors, 'sign'))}
          footer={footerDespaused(setCloseModalDespaused, sendActionToDespause)}
          canClose={true}
          onHide={setCloseModalDespaused}
        />

        <Loader/>
    </>
  );
};
export default TurnForeignList;
